import React from 'react'

export const AdminSvg = ({ color }) => {
    return (
    <svg className="svg-icon" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="22" height="22" rx="3" stroke={color} stroke-width="2"/>
        <path d="M18.75 11.25C18.75 15.4125 15.87 19.305 12 20.25C8.13 19.305 5.25 15.4125 5.25 11.25V6.75L12 3.75L18.75 6.75V11.25ZM12 18.75C14.8125 18 17.25 14.655 17.25 11.415V7.725L12 5.385L6.75 7.725V11.415C6.75 14.655 9.1875 18 12 18.75ZM12 7.5C12.5967 7.5 13.169 7.73705 13.591 8.15901C14.0129 8.58097 14.25 9.15326 14.25 9.75C14.25 10.7325 13.6275 11.565 12.75 11.8725V13.5H14.25V15H12.75V16.5H11.25V11.8725C10.8105 11.7183 10.43 11.4311 10.1612 11.0507C9.89236 10.6704 9.74865 10.2157 9.75 9.75C9.75 9.15326 9.98705 8.58097 10.409 8.15901C10.831 7.73705 11.4033 7.5 12 7.5V7.5ZM12 9C11.8011 9 11.6103 9.07902 11.4697 9.21967C11.329 9.36032 11.25 9.55109 11.25 9.75C11.25 9.94891 11.329 10.1397 11.4697 10.2803C11.6103 10.421 11.8011 10.5 12 10.5C12.1989 10.5 12.3897 10.421 12.5303 10.2803C12.671 10.1397 12.75 9.94891 12.75 9.75C12.75 9.55109 12.671 9.36032 12.5303 9.21967C12.3897 9.07902 12.1989 9 12 9Z" fill={color}/>
    </svg>

    )
}