import React from 'react'
import '../../Styles/styles.css'

export const StatusComponent = (props) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px' }}>
            <p className={props.value ? 'processed-span bold' : 'not-processed-span bold'} >{props.value ? 'SI' : 'NO'}</p>
        </div>
    )
}
  