import Swal from 'sweetalert2'

// modal para mostrar mensaje simple 
// simple modal message 
export const sweetalert = (title, text, icon) => {
    Swal.fire({
        title,
        text,
        icon,
        allowOutsideClick: false,
        showCloseButton: true
        })  
}

// modal para validar el logout 
// validate logout modal 
export const sweetalertLogout = (title, text, icon, logout) => {
    Swal.fire({
        title,
        text,
        icon,
        allowOutsideClick: false,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, salir',
        confirmButtonColor: "var(--primary)",
        cancelButtonText: 'No.',
        }).then((result) => {
            if (result.isConfirmed){
                logout()
            }
        })
}

export const sweetalertRequest = async (title, text, icon, deleteUserData, id) => {
    const data = Swal.fire({
        title,
        text,
        icon,
        allowOutsideClick: false,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar',
        cancelButtonText: 'No.',
        confirmButtonColor: "var(--primary)",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            return await deleteUserData(id)
          },
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then((result) => {
            if (result.isConfirmed){
                return result
            }
        })
        return data
}