import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import AppContext from '../../context/app/AppContext'
import '../../Styles/styles.css'
// assets 
import Logo from "../../assets/logo.svg";
import Arrow from "../../assets/arrow.svg";
import RejectSVG from "../../assets/customSVG/reject-svg";
import ListSVG from "../../assets/customSVG/List-svg";
import MethodsSVG from "../../assets/customSVG/Methods-svg";
import PermisionsSVG from "../../assets/customSVG/Permisions-svg";
import WithdrawalsSVG from "../../assets/customSVG/Withdrawals-svg";

import YummyTrans from "../../assets/yummy-trans.svg";
import YummyTransSelected from "../../assets/yummy-trans-selected.svg";
import WinkTrans from "../../assets/wink-trans.svg";
import WinkTransSelected from "../../assets/wink-trans-selected.svg";

// components 
import { UserInfo } from './UserInfo'




const Menu = () => {
    
    const { requestLogout, userData } = useContext(AppContext);

    const { permissions, user } = userData

    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const [location, setLocation] = useState('')

    const handleOpenMenu = () => {
        setOpen(!open)
    }

    const title = {
        '/rejected-transactions-yummy': 'Yummy | Transacciones rezagadas',
        '/rejected-transactions-credit-yummy': 'Yummy | Transacciones de crédito rezagadas',
        '/transactions-list-yummy': 'Yummy | Transacciones procesadas',
        '/transactions-list-credit-yummy': 'Yummy | Transacciones crédito procesadas',
        '/rejected-transactions-wink': 'Wink | Transacciones rezagadas',
        '/transactions-list-wink': 'Wink | Transacciones procesadas',
        '/admin-permissions': 'Administrar permisos',
        '/methods': 'Administrar Metodos',
        '/admin-withdrawals': 'Lista de Retiros',
    }

    const colorTitleYummy = () => {
        if(location === '/rejected-transactions-yummy' || location === '/transactions-list-yummy'){
            return 'var(--primary)'
        }
        return '#888'
    }
    const colorTitleWink = () => {
        if(location === '/rejected-transactions-wink' || location === '/transactions-list-wink'){
            return 'var(--primary)'
        }
        return '#888'
    }


    return(
        <>
            <div className={open ? 'hamb-menu shadows open-menu' : 'hamb-menu shadows closed-menu' }>
                <div className={open ? 'menu-toggle menu-toggle-open shadows' :'menu-toggle menu-toggle-closed shadows'} onClick={handleOpenMenu}>
                    <img src={Arrow} alt="abrir/cerrar menu" />
                </div>
                {/* yummy  */}
                {
                    userData.roles.length > 0 &&
                    <div className='navlinks-container'>
                        <div className='flex' style={{ margin: open ? '15px 0 0 5px' : '15px 5px 0' }}>
                            <img src={location.includes('yummy') ? YummyTransSelected : YummyTrans} alt="YummyTransSelected" className={open && 'mr-m'} style={{ transition: '0.3s' }} />
                            <p className={open ? 'show-text' : 'hide-text'} style={{ margin: '0', color: colorTitleYummy() }}>Transacciones Yummy</p>
                        </div>
                        <NavLink 
                            to="/rejected-transactions-yummy" 
                            className={open ? "navLink ml-m" : "navLink" }
                            isActive={(match, location)=>{
                                if(match){
                                    setLocation(location.pathname)
                                }
                                return match;
                            }}
                            activeClassName="selected"
                            >
                            <div style={{ height: 24 }}>
                                {location === '/rejected-transactions-yummy' ? <RejectSVG color={'var(--primary)'} /> : <RejectSVG color={'var(--mid-gray)'} />}
                            </div>
                            <p className={open ? 'show-text' : 'hide-text'}>Rezagadas</p>
                        </NavLink>
                        <NavLink 
                            to="/transactions-list-yummy" 
                            className={open ? "navLink ml-m" : "navLink" }
                            isActive={(match, location)=>{
                                if(match){
                                    setLocation(location.pathname)
                                }
                                return match;
                            }}
                            activeClassName="selected">
                            <div style={{ height: 24 }}>
                                {location === '/transactions-list-yummy' ? <ListSVG color={'var(--primary)'} /> : <ListSVG color={'var(--mid-gray)'} />}
                            </div>
                            <p className={open ? 'show-text' : 'hide-text'}>Procesadas</p>
                        </NavLink>
                    </div>
                }
                {/* Creditos Yummy */ }
                {
                    userData.roles.length > 0 &&
                    <div className='navlinks-container'>
                        <div className='flex' style={{ margin: open ? '15px 0 0 5px' : '15px 5px 0' }}>
                            <img src={location.includes('yummy') ? YummyTransSelected : YummyTrans} alt="YummyTransSelected" className={open && 'mr-m'} style={{ transition: '0.3s' }} />
                            <p className={open ? 'show-text' : 'hide-text'} style={{ margin: '0', color: colorTitleYummy() }}>Transacciones Yummy</p>
                        </div>
                        <NavLink 
                            to="/rejected-transactions-credit-yummy" 
                            className={open ? "navLink ml-m" : "navLink" }
                            isActive={(match, location)=>{
                                if(match){
                                    setLocation(location.pathname)
                                }
                                return match;
                            }}
                            activeClassName="selected"
                            >
                            <div style={{ height: 24 }}>
                                {location === '/rejected-transactions-credit-yummy' ? <RejectSVG color={'var(--primary)'} /> : <RejectSVG color={'var(--mid-gray)'} />}
                            </div>
                            <p className={open ? 'show-text' : 'hide-text'}>Rezagadas</p>
                        </NavLink>
                        <NavLink 
                            to="/transactions-list-credit-yummy" 
                            className={open ? "navLink ml-m" : "navLink" }
                            isActive={(match, location)=>{
                                if(match){
                                    setLocation(location.pathname)
                                }
                                return match;
                            }}
                            activeClassName="selected">
                            <div style={{ height: 24 }}>
                                {location === '/transactions-list-credit-yummy' ? <ListSVG color={'var(--primary)'} /> : <ListSVG color={'var(--mid-gray)'} />}
                            </div>
                            <p className={open ? 'show-text' : 'hide-text'}>Procesadas</p>
                        </NavLink>
                    </div>
                }
                {/* wink  */}
                {
                    userData.roles_wink.length > 0 &&
                    <div className='navlinks-container'>
                        <div className='flex' style={{ margin: open ? '15px 0 0 5px' : '15px 5px 0' }}>
                            <img src={location.includes('wink') ? WinkTransSelected : WinkTrans} alt="WinkTransSelected" className={open && 'mr-m'} style={{ transition: '0.3s' }} />
                            <p className={open ? 'show-text' : 'hide-text'} style={{ margin: '0', color: colorTitleWink() }}>Transacciones Wink</p>
                        </div>
                        <NavLink 
                            to="/rejected-transactions-wink" 
                            className={open ? "navLink ml-m" : "navLink" }
                            isActive={(match, location)=>{
                                if(match){
                                    setLocation(location.pathname)
                                }
                                return match;
                            }}
                            activeClassName="selected"
                            >
                            <div style={{ height: 24 }}>
                                {location === '/rejected-transactions-wink' ? <RejectSVG color={'var(--primary)'} /> : <RejectSVG color={'var(--mid-gray)'} />}
                            </div>
                            <p className={open ? 'show-text' : 'hide-text'}>Rezagadas</p>
                        </NavLink>
                        <NavLink 
                            to="/transactions-list-wink" 
                            className={open ? "navLink ml-m" : "navLink" }
                            isActive={(match, location)=>{
                                if(match){
                                    setLocation(location.pathname)
                                }
                                return match;
                            }}
                            activeClassName="selected">
                            <div style={{ height: 24 }}>
                                {location === '/transactions-list-wink' ? <ListSVG color={'var(--primary)'} /> : <ListSVG color={'var(--mid-gray)'} />}
                            </div>
                            <p className={open ? 'show-text' : 'hide-text'}>Procesadas</p>
                        </NavLink>
                    </div>
                }

                <NavLink 
                        to="/admin-withdrawals" 
                        className={open ? "navLink ml-m" : "navLink" }
                        isActive={(match, location)=>{
                            if(match){
                                setLocation(location.pathname)
                            }
                            return match;
                        }}
                        activeClassName="selected">
                        <div style={{ height: 24 }}>
                            {location === '/admin-withdrawals' ? <WithdrawalsSVG color={'var(--primary)'} /> : <WithdrawalsSVG color={'var(--mid-gray)'} />}
                        </div>
                        <p className={open ? 'show-text' : 'hide-text'}>Lista de Retiros</p>
                    </NavLink>
                {
                    userData &&
                    (userData.roles.includes('administrator') || 
                    userData.roles_wink.includes('administrator')) && 
                    <>
                    <NavLink 
                        to="/admin-permissions" 
                        className={open ? "navLink ml-m" : "navLink" }
                        isActive={(match, location)=>{
                            if(match){
                                setLocation(location.pathname)
                            }
                            return match;
                        }}
                        activeClassName="selected">
                        <div style={{ height: 24 }}>
                            {location === '/admin-permissions' ? <PermisionsSVG color={'var(--primary)'} /> : <PermisionsSVG color={'var(--mid-gray)'} />}
                        </div>
                        <p className={open ? 'show-text' : 'hide-text'}>Administrar permisos</p>
                    </NavLink>
                    {/* <NavLink 
                        to="/admin-methods" 
                        className="navLink" 
                        isActive={(match, location)=>{
                            if(match){
                                setLocation(location.pathname)
                            }
                            return match;
                        }}
                        activeClassName="selected">
                        <div style={{ height: 24 }}>
                            {location === '/admin-methods' ? <MethodsSVG color={'var(--primary)'} /> : <MethodsSVG color={'var(--mid-gray)'} />}
                        </div>
                        <p className={open ? 'show-text' : 'hide-text'}>Administrar Metodos</p>
                    </NavLink> */}
                    </>
                }
            </div>
            { open && <div className='overlay' onClick={handleOpenMenu} /> }
            <div className="method-menu shadows">
                <img src={Logo} alt="logo panel de pagos" />
                <p className='title-header'>{title[location]}</p>
                <div className="user-info" onClick={() => setShow(!show)}>
                    <p>{user.first_name} {user.last_name}</p>
                    <img src={user.user_img} className="user-img" />
                </div>
                {
                    show &&
                    <UserInfo data={userData} requestLogout={requestLogout} setShow={setShow} />
                }
            </div>
        </>
    )
}

export default Menu