import React, { useState, useEffect, useContext } from "react";
import "../../../Styles/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import TransContext from "../../../context/trans/TransContext";
import AppContext from "../../../context/app/AppContext";
import { ToastContainer, toast } from 'react-toastify';
import { RejectedModal } from '../../modals/RejectedModal'
import { RevertModal } from '../../modals/RevertModal'
// date picker 
import { TransactionTable } from '../TransactionTable'
import { SearchEngine } from '../../searchEmgine/SearchEngine'


import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const TransactionsList = ({ source }) => {

    // contexto que maneja las transacciones 
    // transactions context 
    const {
        getTransactionsList, //func for get transactions list
        onBtnExport, //ag-grid api 
        showBtnCsv, //to show scv button 
        setShowBtnCsv, //to change state show csv button
        loading, // loader 
        loadingSearch, // search engine loader
        apiTypeSource,
        setApiTypeSource // set api type source
    } = useContext(TransContext);



    const { userData } = useContext(AppContext)

    // para abrir o cerrar el modal 
    // to open or close modal 
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenRevert, setIsOpenRevert] = useState(false)

    const [emptyState, setEmptyState] = useState(false)
    // variable para definir si el buscador tiene resultados o no 
    // var for search result 
    const [noCoincidence, setNoCoincidence] = useState(false)

    // para pasarle la data de la transaccion al modal por medio de props
    // to pass by props, the transaction data to handle on modal 
    const [transData, setTransData] = useState({})

    // variable que almacena las transacciones filtradas 
    // var for filters transactions 
    const [transactionsFilter, setTransactionsFilter] = useState([])

    // variable que almacena las transacciones no de la primera carga 
    // var for first get transactions 
    const [transactions, setTransactions] = useState([])

    // funcion que consulta la lista de trasacciones 
    // function for get all transactions 
    useEffect(async () => {
        setApiTypeSource(source)
        const trans = await getTransactionsList(0, source)
        setTransactions(trans)
    }, [])

    // funcion para manejar el cerrado del modal 
    // to handle close modal 
    const handleClose = () => {
        setIsOpen(false)
        setIsOpenRevert(false)
    }

    useEffect(() => {

        (loading || loadingSearch) ? setShowBtnCsv(false) : setShowBtnCsv(true)
    }, [loading, loadingSearch])

    return (
        <>
            <RevertModal
                isOpen={isOpenRevert}
                setIsOpen={setIsOpenRevert}
                data={transData}
                handleClose={handleClose}
                setTransactions={setTransactions}
                transactionsFilter={transactionsFilter}
                setTransactionsFilter={setTransactionsFilter}
            />
            <RejectedModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                data={transData}
                handleClose={handleClose}
                setTransactions={setTransactions}
                transactionsFilter={transactionsFilter}
                setTransactionsFilter={setTransactionsFilter}
                isRejected={false} />
            <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="trans-container">
                <div className="trans-buscador-container">
                    {/* 
                    componente de buscador que recibe funciones y variables para su funcionamiento 
                    search engine component, it recives variables and func to work
                    */}
                    <SearchEngine
                        // functions 
                        getTransactions={getTransactionsList}
                        setNoCoincidence={setNoCoincidence}
                        setTransactionsFilter={setTransactionsFilter}
                        setTransactions={setTransactions}
                        // variables 
                        history={true}
                        processed={true}
                        setEmptyState={setEmptyState}
                        source={source}
                    />
                    {
                        noCoincidence && <p className="no-coincidence">No se encontraron coincidencias...</p>
                    }
                </div>
                {/* scv button conditional  */}
                {
                    showBtnCsv &&
                    <div className="export-btn-container">
                        <button className="btn-yummy" style={{ marginLeft: 'auto' }} onClick={onBtnExport}>Exportar CSV</button>
                    </div>
                }
                <div className="trans-table-container panel panel-default ag-theme-alpine" style={{ width: '90%' }}>
                    <TransactionTable
                        isRejected={false}
                        trans={transactionsFilter.length === 0 ? transactions : transactionsFilter}
                        setIsOpen={setIsOpen}
                        setIsOpenRevert={setIsOpenRevert}
                        setTransData={setTransData}
                        setTransactionsFilter={setTransactionsFilter}
                        setTransactions={setTransactions}
                    />
                </div>
            </div>
        </>
    );
};

export default TransactionsList;
