import React, { useState, useContext } from 'react';
import TransContext from '../../context/trans/TransContext'
import SearchIcon from '../../assets/search.svg'
import { useEffect } from 'react';

export const Delivery = ({ setData })=> {

	const { getUserDelivery } = useContext(TransContext)
    const [inputData, setInputData] = useState('');
    const [user, setUser] = useState({})
    const [results, setResults] = useState(false)

    const onClick = ()=> {
        getUserDelivery(inputData).then(({data}) => {
            if (!data.id) {
                setResults(true)
                setUser({})
            } 
            else {
                setResults(false)
                setUser(data)
            }
        })
    }

    useEffect(()=> {
        if(user.phone){
            setData(user.phone)
        }
    },[user])

    return (
        <>
            <p className="input-label" style={{ width: '50%' }}>Número de teléfono</p>
            <div className="search-user-container" style={{ width: '50%' }}>
                <input 
                    className="input-user-delivery"
                    id="search-engine" 
                    type="text"
                    placeholder="Ej: +584128883349"
                    value={inputData}
                    onChange={(e)=> setInputData(e.target.value)}
                />
                <div className="search-user" onClick={onClick}>{<img src={SearchIcon} className="search-icon" />}</div>
            </div>
            <div className="user-selected-conatiner-delivery">
                <p>ID: <span className="user-span">{user.id}</span></p>
                <p>Nombre: <span className="user-span">{user.name}</span></p>
                <p>Correo: <span className="user-span">{user.email}</span></p>
                <p>Telefono: <span className="user-span">{user.phone}</span></p>
            </div>
            { results && <p className="no-coincidence">No se encontraron coincidencias...</p>}
        </>
    )
}