import React, { useEffect, useState } from 'react'
import MethodCard from './MethodCard'
import dummyData from './dummy-methods.json'
import './styles.css'

const AdminMethods = () => {

    const [methods, setMethods] = useState([])

    const getData = () => {
        const data = dummyData
        return data
    }

    useEffect(()=> {
       const data = getData()
       setMethods(data)
    },[])

    return (
        <div className="methods-container">
            {
                dummyData.data.map(method => {
                    return <MethodCard data={method} />
                })
            }
        </div>
    )
}

export default AdminMethods