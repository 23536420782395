import React from 'react'

const CustomSvg = ({ color }) => {
    return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9.25" stroke={color} stroke-width="1.5"/>
    <path d="M5 6.84667L5.99556 6L19 17.1467L18.0122 18L15.1578 15.5533C14.6756 16 13.9756 16.3667 13.1667 16.5467V18H10.8333V16.5467C9.25444 16.2067 8.11111 15.1933 8.11111 14H9.66667C9.66667 14.72 10.7322 15.3333 12 15.3333C12.8789 15.3333 13.6644 15.04 14.0611 14.6133L11.7511 12.6333C10.1178 12.28 8.11111 11.8333 8.11111 10C8.11111 9.84667 8.11111 9.7 8.16556 9.56L5 6.84667ZM10.8333 7.45333V6H13.1667V7.45333C14.7456 7.79333 15.8889 8.80667 15.8889 10H14.3333C14.3333 9.28 13.2678 8.66667 12 8.66667C11.7122 8.66667 11.44 8.7 11.1833 8.75333L9.97778 7.72L10.8333 7.45333Z" fill={color}/>
    </svg>
    )
}

export default CustomSvg