import React from "react";
import AdminState from '../context/admin/AdminState'
import Admin from '../components/adminMethods/AdminMethods'
import Menu from '../components/menu/Menu'

const AdminMethods = () => {
  return (
    <>
      <AdminState>
          <Menu />
          <Admin />
      </AdminState>
    </>
  );
};

export default AdminMethods;
