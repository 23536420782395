import React, { useState, useContext } from 'react';
import TransContext from '../../context/trans/TransContext';
import SearchIcon from '../../assets/search.svg';
import { useEffect } from 'react';

export const Yummer = ({ setData }) => {
  const { getUsersYummers } = useContext(TransContext);
  const [inputData, setInputData] = useState('');
  const [user, setUser] = useState({});
  const [noResults, setNoResults] = useState(false);

  const onClick = async () => {
    if(inputData.length > 0){
      const res = await getUsersYummers(inputData)
      if(res.length === 0){
          setNoResults(true);
          setUser({});
          return
        }
        setNoResults(false);
        setUser({...res[0], num_doc: inputData});
    }
  };

  useEffect(() => {
    if (user && user.driver_id) {
      setData([user]);
    }
  }, [user]);

  return (
    <>
      <p className="input-label" style={{ width: '50%' }}>
        Número de cedula
      </p>
      <div className="search-user-container" style={{ width: '50%' }}>
        <input
          className="input-user-delivery"
          id="search-engine"
          type="text"
          placeholder="Ej: 20123456"
          value={inputData}
          onChange={(e) => setInputData(e.target.value)}
        />
        <div className="search-user" onClick={onClick}>
          {<img src={SearchIcon} className="search-icon" />}
        </div>
      </div>
      <div className="user-selected-conatiner-delivery">
        <p>
          ID: <span className="user-span">{user && user.driver_id}</span>
        </p>
        <p>
          Nombre: <span className="user-span">{user && user.driver_name}</span>
        </p>
        <p>
          Documento: <span className="user-span">{user && user.num_doc}</span>
        </p>
        <p>
          Telefono: <span className="user-span">{user && user.driver_phone}</span>
        </p>
      </div>
      {noResults && (
        <p className="no-coincidence">No se encontraron coincidencias...</p>
      )}
    </>
  );
};
