import React from "react";
import AdminState from '../context/admin/AdminState'
import AdminWithdrawalsComponent from '../components/adminWithdrawals/AdminWithdrawals'
import Menu from '../components/menu/Menu'

const AdminWithdrawals = () => {
  return (
    <>
      <AdminState>
          <Menu />
          <AdminWithdrawalsComponent />
      </AdminState>
    </>
  );
};

export default AdminWithdrawals;
