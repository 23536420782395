import React, { useState, useContext} from 'react';
import { Modal, Paper } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@material-ui/core";
import './modal.css'
import TransContext from "../../context/trans/TransContext";
import AppContext from "../../context/app/AppContext";
import { Loader } from '../loader/Loader'
import { sweetalert } from '../../helpers/SweetAlert';

export const RevertModal = ({ 
  isOpen, 
  setIsOpen, 
  data, 
  handleClose, 
  setTransactionsFilter,
  setTransactions
} ) => {

    const { 
      getTransactionsList, // to get transactions list
      loadingProcess, // loader
      revertTransaction, // for revert transaction 
      revertTransactionYummer // for revert transaction yummer
    } = useContext(TransContext);
    const { userData } = useContext(AppContext)

    const dataTransaction = [
      {
        key: 'ID',
        value: data.auto_incremental_id
      },
      {
        key: 'Nombre',
        value: data.name
      },
      {
        key: 'Concepto',
        value: data.concept
      },
      {
        key: 'Monto',
        value: `$ ${data.amount}`
      },
      {
        key: 'Fecha',
        value: data.date ? data.date : ''
      },
      {
        key: 'Hora',
        value: data.time
      }
    ]


    const clearTable = () => {
      setTransactionsFilter([])
      setTransactions([])
    }

    // input data
    const [inputData, setInputData] = useState('')
    const disableButtonStyles = {backgroundColor: "#c4c4c4", cursor: "default"} 

    // close modal func, initialize data
    const closeModal = () => {
      setInputData('')
      handleClose()
    }

    // to set input data 
    const onChange = (e) => {
        // console.log(e.target.value)
        setInputData(e.target.value)

    }

    const renderData = (key, value) => {
      return (
        <div className="data-container">
          <p className="key">{key}:</p>
          <p className="value">{value}</p>
        </div>
      )
    }

    const revert = async () => {
      if (inputData.length > 0) {
        let res;
        if(data.user_type === "YUMMER"){
          res = await revertTransactionYummer(data, inputData)
        }else {
          res = await revertTransaction(data._id.$oid, inputData)
        }
        // console.log(res)
        if(res.success){
            closeModal()
            clearTable()
            sweetalert("listo", "La transacción se reverso correctamente!", 'success')
            const list = await getTransactionsList()
            setTransactions(list)
        }else {
          closeModal()
          sweetalert("Error", "Error al reversar la transacción", 'error')
        }
      }
    }

    const renderInput = () => {
      return (
        <>
        {
          (userData.roles.includes("administrator") && !data.reversed_by || userData.roles.includes("moderator") && !data.reversed_by ) &&
          <>
            <p className="input-label text-center">Deseas revertirla?</p>
            <p className="input-label">Motivo</p>
            <input 
                  className="input-modal"
                  style={{width: '80%'}}
                  id="search-engine" 
                  type="text"
                  value={inputData}
                  onChange={(e)=> onChange(e)}
              />
          </>
        }
        </>
      )
    }

    const renderButton = () => {
      // console.log(userData.roles)
      return (
        <>
        {
          userData.roles.includes("administrator") && data.reversed_by ?
          <>
            {
              loadingProcess ? 
              <Loader color='#fff' /> : 
              <button type="submit" onClick={() => revert()} className={inputData.length > 0 ? "btn-process btn-bg" : "btn-process btn-bg-disabled"}>REVERTIR</button>
            }
          </>
          :
          <p>Esta transacción ya fue revertida anteriormente</p>
        }
        </>
      )
    }

    return (
        
          <Modal 
            className="modal" 
            open={isOpen}
            onClose={closeModal}
          >
            <Paper className="paper">
            <HighlightOffIcon className="close-btn" onClick={()=> closeModal()} />
            {
              data && 
              <div className="info-container">
                {
                  dataTransaction.map(data => {
                    return renderData(data.key, data.value)
                  })
                }
              </div>
            }
              <div className="selectionContainer no-border">
                <p className="input-label text-center">Esta transaccion ya fue procesada para <span className="purple-text">{data.is_rides_recharge ? "Rides" : "Delivery"}</span></p>
                {renderInput()}
              </div>
              {
                data.reversed_by ?
                renderButton()
                :
                <>
                  {
                    loadingProcess ? 
                    <Loader color='#fff' /> : 
                    <button type="submit" onClick={() => revert()} className={inputData.length > 0 ? "btn-process btn-bg" : "btn-process btn-bg-disabled"}>REVERTIR</button>
                  }
                </>
              }
            </Paper>
          </Modal>
    )
}

