import React from "react";
import TransState from '../../context/trans/TransState'
import AdminState from '../../context/admin/AdminState'
import RejectedTransactionsScreen from '../../components/trans/wink/RejectedTransactions'
import Menu from '../../components/menu/Menu'

const RejectedTransactions = ({ source = 'Debit' }) => {
  return (
    <>
      <AdminState>
        <TransState>
          <Menu />
          <RejectedTransactionsScreen source={source} />
        </TransState>
      </AdminState>
    </>
  );
};

export default RejectedTransactions;
