import React from "react";
import AppState from '../context/app/AppState'
import Home from '../components/home/Home'


const HomeScreen = () => {
  return (
    <>
        <AppState>
          <Home />
        </AppState>
    </>
  );
};

export default HomeScreen;
