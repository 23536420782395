import React from 'react'

const CustomSvg = ({ color }) => {
    return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3065 11.2667C10.5226 10.8733 9.94892 10.4667 9.94892 9.83333C9.94892 9.10667 10.7426 8.6 12.0707 8.6C13.4695 8.6 13.9882 9.16667 14.0354 10H15.7721C15.7171 8.85333 14.8919 7.8 13.2495 7.46V6H10.8919V7.44C9.36739 7.72 8.14145 8.56 8.14145 9.84667C8.14145 11.3867 9.64244 12.1533 11.835 12.6C13.7996 13 14.1925 13.5867 14.1925 14.2067C14.1925 14.6667 13.8075 15.4 12.0707 15.4C10.4519 15.4 9.81532 14.7867 9.72888 14H8C8.0943 15.46 9.3831 16.28 10.8919 16.5533V18H13.2495V16.5667C14.7819 16.32 16 15.5667 16 14.2C16 12.3067 14.0904 11.66 12.3065 11.2667Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.04938 11C2.01672 11.3289 2 11.6625 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C8.55216 2 5.51186 3.7449 3.714 6.3998L4.94315 7.2602C6.46955 4.99209 9.06053 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 11.6617 3.51976 11.328 3.5582 11H2.04938Z" fill={color}/>
    <path d="M3.16501 8.71411L2.79001 5.0335L6.54001 7.19857L3.16501 8.71411Z" fill={color}/>
    </svg>
    )
}

export default CustomSvg