import React from 'react'

const CustomSvg = ({ color }) => {
    return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9.25" stroke={color} stroke-width="1.5"/>
    <path d="M11.5 6C12.2957 6 13.0587 6.28973 13.6213 6.80546C14.1839 7.32118 14.5 8.02065 14.5 8.75C14.5 9.95083 13.67 10.9683 12.5 11.3442V13.3333H14.5V15.1667H12.5V17H10.5V11.3442C9.91404 11.1557 9.40662 10.8046 9.04822 10.3398C8.68982 9.87489 8.49822 9.31925 8.50001 8.75C8.50001 8.02065 8.81608 7.32118 9.37869 6.80546C9.9413 6.28973 10.7044 6 11.5 6ZM11.5 7.83333C11.2348 7.83333 10.9804 7.92991 10.7929 8.10182C10.6054 8.27373 10.5 8.50688 10.5 8.75C10.5 8.99312 10.6054 9.22627 10.7929 9.39818C10.9804 9.57009 11.2348 9.66667 11.5 9.66667C11.7652 9.66667 12.0196 9.57009 12.2071 9.39818C12.3946 9.22627 12.5 8.99312 12.5 8.75C12.5 8.50688 12.3946 8.27373 12.2071 8.10182C12.0196 7.92991 11.7652 7.83333 11.5 7.83333Z" fill={color}/>
    </svg>
    )
}

export default CustomSvg
