import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// components 
import RejectedTransactions from "../../screen/yummy/RejectedTransactions";
import TransactionsList from "../../screen/yummy/TransactionsList";
import RejectedTransactionsWink from "../../screen/wink/RejectedTransactions";
import TransactionsListWink from "../../screen/wink/TransactionsList";
import AdminPermissions from "../../screen/Admin";
import AdminMethods from "../../screen/AdminMethods";
import AdminWithdrawals from "../../screen/AdminWithdrawals";
import Login from '../../screen/Login'
import NotResponsive from '../../screen/NotResponsive';
// context 
import AppContext from '../../context/app/AppContext'

// ruta PublicSharp, soilo se puede acceder al login 
// public Route, only login is allowed 
export const RouterPublic = () => {

  return (
    <BrowserRouter>
      {
        <Switch>
          <Route path="/login" component={Login} />
          <Route exact path="/not-responsive" component={NotResponsive} />
          <Redirect path="/" to="/login" />
        </Switch>
      }
    </BrowserRouter>
  );
};

// ruta privada, solo disponible si el usuario esta autenticado 
// private route, only allowed if the user is authenticated 
export const RouterPrivate = () => {

  const { userData } = useContext(AppContext)

  // const roles = userData.roles
  const rolesData = userData

  const hasRoles = rolesData.roles.length > 0;
  const hasWinkRoles = rolesData.roles_wink.length > 0;
  const isAdmin = rolesData.roles.includes('administrator') || rolesData.roles_wink.includes('administrator');

  return (
    <BrowserRouter>
      <Switch>
        {hasRoles && <Route exact path="/transactions-list-yummy" component={TransactionsList} />}
        {hasRoles && <Route exact path="/rejected-transactions-yummy" component={RejectedTransactions} />}
        {hasRoles && <Route exact path="/transactions-list-credit-yummy" render={() => <TransactionsList source='Credit' />} />}
        {hasRoles && <Route exact path="/rejected-transactions-credit-yummy" render={() => <RejectedTransactions source='Credit' />} />}
        {hasWinkRoles && <Route exact path="/rejected-transactions-wink" component={RejectedTransactionsWink} />}
        {hasWinkRoles && <Route exact path="/transactions-list-wink" component={TransactionsListWink} />}
        {isAdmin && <Route exact path="/admin-permissions" component={AdminPermissions} />}
        {isAdmin && <Route exact path="/admin-methods" component={AdminMethods} />}
        <Route exact path="/admin-withdrawals" component={AdminWithdrawals} />
        <Route exact path="/not-responsive" component={NotResponsive} />
        <Redirect path="/" to={hasRoles ? "/rejected-transactions-yummy" : "/rejected-transactions-wink"} />
      </Switch>
    </BrowserRouter>
  );
};