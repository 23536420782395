import React, { useState, useContext, useEffect } from 'react';

import { Rides } from './Rides';
import { Dingo } from './Dingo';

import { Modal, Paper } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import './modal.css';
import TransContext from '../../context/trans/TransContext';
import { Loader } from '../loader/Loader';
import { sweetalert } from '../../helpers/SweetAlert';
import Revert from '../../assets/permissions/revert.svg';
import { Delivery } from './Delivery';
import { Yummer } from './Yummer';

export const RejectedModal = ({
  isOpen,
  setIsOpen,
  data,
  handleClose,
  setTransactions,
  transactionsFilter,
  setTransactionsFilter,
  isRejected,
}) => {
  const {
    getRejectedTransactions, //to get rejected transactions
    getTransactionsList, // to get transactions list
    processTransaction, // to process transaction
    processTransactionHugo, // to process transaction and add yummy credits
    processYummer,
    processRides,
    processDingo,
    processDiffered,
    loadingProcess, // loader
    setIsCorporate,
    email
  } = useContext(TransContext);

  const type = ['Procesar', 'Delivery', 'Rides', 'Dingo'];
  const dataTransaction = [
    {
      key: 'ID',
      value: data.auto_incremental_id,
    },
    {
      key: 'Nombre',
      value: data.name,
    },
    {
      key: 'Concepto',
      value: data.concept,
    },
    {
      key: 'Monto',
      value: `$ ${data.amount}`,
    },
    {
      key: 'Fecha',
      value: data.date ? data.date : '',
    },
    {
      key: 'Hora',
      value: data.time,
    },
  ];

  // func to clear table data while is fetching data
  const clearTable = () => {
    setTransactionsFilter([]);
    setTransactions([]);
  };

  // input data
  const [inputData, setInputData] = useState('');

  // input radio value to change mode
  const [value, setValue] = useState('');

  // will allow us to get the entire info of a user.
  const [extraData, setExtraData] = useState({});

  // func to set the value of radio button
  const handleChange = (event) => {
    setInputData('');
    setValue(event.target.value);
  };

  // close modal func, initialize data
  const closeModal = () => {
    setInputData('');
    setValue('');
    handleClose();
    setIsCorporate(false);
  };


  // process func, evaluate it is for hugo or normal process
  const processType = () => {
    switch (value) {
      case 'Delivery':
        handleProcessHugo();
        break;
      case 'Rides':
        handleProcessRides();
        break;
      case 'Dingo':
        handleProcessDingo();
        break;
      case 'Yummer':
        handleProcessYummer();
        break;
      default:
        process();
    }
  };

  //function for process dingo
  const handleProcessDingo = async () => {
    // console.log('dingo', data, inputData)
    const req = await processDingo({
      inputData,
      ...data,
      ...extraData,
    });
    let trans;
    closeModal();

    if (req.success !== false) {
      sweetalert('Transaccion Procesada!', req.message, 'success');
    } else {
      sweetalert('Error', req.message, 'error');
    }

    clearTable();
    // reload the transaction list
    if (isRejected) {
      trans = await getRejectedTransactions();
    } else {
      trans = await getTransactionsList();
    }

    setTransactions(trans);
  };

  //function for proccess rides
  const handleProcessRides = async () => {
    const req = await processRides(data);
    let trans;
    closeModal();
    // console.log(req)
    if (req.success !== false) {
      sweetalert('Transaccion Procesada!', req.message, 'success');
    } else {
      sweetalert('Error', req.message, 'error');
    }

    clearTable();
    if (isRejected) {
      trans = await getRejectedTransactions();
    } else {
      trans = await getTransactionsList();
    }
    setTransactions(trans);
  };

  
  // process func adding wallet credits
  const handleProcessHugo = async () => {
    if (inputData.length > 0) {
      const req = await processTransactionHugo(
        data,
        inputData,
        'YUMMY DELIVERY'
      );
      let trans;

      // if false success, show error modal
      if (req.success === false) {
        closeModal();
        sweetalert('Error!', req.message, 'error');
        clearTable();
        if (isRejected) {
          trans = await getRejectedTransactions();
        } else {
          trans = await getTransactionsList();
        }
        setTransactions(trans);
      } else {
        closeModal();
        sweetalert('Listo!', 'Transaccion procesada con exito!', 'success');
        let trans;
        clearTable();

        // reload the transaction list
        if (isRejected) {
          trans = await getRejectedTransactions();
        } else {
          trans = await getTransactionsList();
        }
        setTransactions(trans);
      }
    } else {
      setIsOpen(false);
      sweetalert('Error', 'Escribe un numero valido', 'warning');
    }
  };

  const handleProcessYummer = async () => {
    // console.log('yummer', data)
    try {
      if (inputData) {
        const req = await processYummer({...data, ...inputData[0]});
        let trans;
  
        // // if false success, show error modal
        if (!req.success) {
          closeModal();
          sweetalert('Error!', "Error al procesar la transacción", 'error');
          clearTable();
          if (isRejected) {
            trans = await getRejectedTransactions();
          } else {
            trans = await getTransactionsList();
          }
          setTransactions(trans);
        } else {
          closeModal();
          sweetalert('Listo!', 'Transaccion procesada con exito!', 'success');
          let trans;
          clearTable();
  
          // reload the transaction list
          if (isRejected) {
            trans = await getRejectedTransactions();
          } else {
            trans = await getTransactionsList();
          }
          setTransactions(trans);
        }
      } else {
        setIsOpen(false);
        sweetalert('Error', 'Escribe una cedula valida', 'warning');
      }
    } catch (error) {
      console.log(" ~ handleProcessYummer ~ error", error)
    }
  }

  // function for normal process
  const process = async () => {
    if (inputData.length > 0) {
      const id = data._id.$oid;
      const req = await processDiffered({
        vertical: data.vertical == "Wink"? "WINK" : "INTERNAL",
        associatedTo: inputData,
        processedBy: email,
      }, id);
      let trans;

      // if false success, show error modal
      if (req.success === false) {
        sweetalert('Error', req.message, 'error');
        clearTable();
        closeModal();
        if (isRejected) {
          trans = await getRejectedTransactions();
        } else {
          trans = await getTransactionsList();
        }
        setTransactions(trans);
      } else {
        sweetalert('Listo!', 'Transaccion procesada con exito!', 'success');
        clearTable();
        closeModal();

        // reload the transaction list
        if (isRejected) {
          trans = await getRejectedTransactions();
        } else {
          trans = await getTransactionsList();

        }
        setTransactions(trans);
      }
    } else {
      setIsOpen(false);
      sweetalert('Error', 'Ingresa un motivo valido', 'warning');
    }
  };


  const renderData = (key, value) => {
    return (
      <div className="data-container">
        <p className="key">{key}:</p>
        <p className="value">{value}</p>
      </div>
    );
  };

  return (
    <Modal
      className="modal"
      open={isOpen}
      // open={true}
      onClose={closeModal}>
      <Paper className="paper">
        <HighlightOffIcon className="close-btn" onClick={() => closeModal()} />
        {data && (
          <div className="info-container">
            {dataTransaction.map((data) => {
              return renderData(data.key, data.value);
            })}
            {data.reversed_by && (
              <div className="revert-transaction-container">
                <img src={Revert} />
                <p className="">Transaccion revertida</p>
                <div className="revert-transaction-poppover">
                  <p className="">Revertido por:</p>
                  <p className="input-label">{data.reversed_by}</p>
                  <p style={{ marginTop: 10 }}>Motivo:</p>
                  <p className="input-label">{data.reverse_motivation}</p>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="selectionContainer">
          <div className="type-container">
            {
              data && data.user_type !== "YUMMER" && data.vertical != "Wink" ?
              <>
                {type.map((type, i) => {
                  return (
                    <label className="selected-container" key={i}>
                      <input
                        type="radio"
                        className="input-radio"
                        name="type"
                        value={type}
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="checkmark">{type}</span>
                    </label>
                  )
                }
                )}
              </>
              :
              <>
                <label className="selected-container">
                  <input
                    // checked={true}
                    type="radio"
                    className="input-radio"
                    name="type"
                    value="Procesar"
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="checkmark">Procesar</span>
                </label>
                {
                  data.vertical != "Wink" && (
                    <label className="selected-container">
                    <input
                      // checked={true}
                      type="radio"
                      className="input-radio"
                      name="type"
                      value="Yummer"
                      onChange={(e) => handleChange(e)}
                      />
                    <span className="checkmark">Yummer</span>
                    </label>
                  )
                }
              </>
            }
          </div>
          {value === 'Procesar' && (
            <>
              <p className="input-label">Motivo</p>
              <input
                className="input-value"
                id="search-engine"
                type="text"
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
              />
            </>
          )}
          {value === 'Delivery' && <Delivery setData={setInputData} />}
          {value === 'Yummer' && <Yummer setData={setInputData} />}
          {value === 'Rides' && <Rides setData={setInputData} />}
          {value === 'Dingo' && (
            <Dingo setData={setInputData} setExtraData={setExtraData} />
          )}
        </div>
        {value !== '' && (
          <>
            {loadingProcess ? (
              <Loader color="#fff" />
            ) : (
              <button
                type="submit"
                onClick={() => processType()}
                disabled={!inputData.length > 0}
                className={
                  inputData.length > 0
                    ? 'btn-process btn-bg'
                    : 'btn-process btn-bg-disabled'
                }>
                ACEPTAR
              </button>
            )}
          </>
        )}
      </Paper>
    </Modal>
  );
};
