import React from 'react'

export const RevertSvg = ({ color }) => {
    return (
<svg className="svg-icon" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="3" stroke={color} stroke-width="2"/>
    <path d="M13.125 9H12V12.75L15.21 14.655L15.75 13.7475L13.125 12.1875V9ZM12.75 5.25C10.9598 5.25 9.2429 5.96116 7.97703 7.22703C6.71116 8.4929 6 10.2098 6 12H3.75L6.72 15.0225L9.75 12H7.5C7.5 10.6076 8.05312 9.27226 9.03769 8.28769C10.0223 7.30312 11.3576 6.75 12.75 6.75C14.1424 6.75 15.4777 7.30312 16.4623 8.28769C17.4469 9.27226 18 10.6076 18 12C18 13.3924 17.4469 14.7277 16.4623 15.7123C15.4777 16.6969 14.1424 17.25 12.75 17.25C11.3025 17.25 9.99 16.6575 9.045 15.705L7.98 16.77C8.6036 17.4005 9.34663 17.9003 10.1656 18.2403C10.9847 18.5802 11.8632 18.7535 12.75 18.75C14.5402 18.75 16.2571 18.0388 17.523 16.773C18.7888 15.5071 19.5 13.7902 19.5 12C19.5 10.2098 18.7888 8.4929 17.523 7.22703C16.2571 5.96116 14.5402 5.25 12.75 5.25" fill={color}/>
</svg>

    )
}