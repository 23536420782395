import ReadSvg from '../../assets/permissions/read.svg'
import EditSvg from '../../assets/permissions/edit.svg'
import RevertSvg from '../../assets/permissions/revert.svg'
import AdminSvg from '../../assets/permissions/admin.svg'

export const rolesCases = {
    administrator: {
        name: 'Administrador',
        img: AdminSvg
    },
    moderator: {
        name: 'Moderador',
        img: RevertSvg
    },
    user: {
        name: 'Usuario',
        img: EditSvg
    },
}