import React, { useState, useEffect, useContext, useCallback } from "react";
import "../../../Styles/styles.css";
import { RejectedModal } from '../../modals/RejectedModal'
import TransContext from "../../../context/trans/TransContext";
import AppContext from "../../../context/app/AppContext";

import { TransactionTable } from '../TransactionTable'
import { SearchEngine } from '../../searchEmgine/SearchEngine'
import { ToastContainer } from 'react-toastify';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// import { getTransactions } from '../../helpers/HelperZelle'


const RejectedTransactions = ({ source }) => {

    // contexto que maneja las transacciones 
    // transactions context 
    const {
        getRejectedTransactions, //func for get rejected transactions
        onBtnExport, //ag-grid api 
        showBtnCsv, //to show scv button 
        setShowBtnCsv, //to change state show csv button
        loading, // loader 
        loadingSearch, // search engine loader
        apiTypeSource, // api type source
        setApiTypeSource // set api type source
    } = useContext(TransContext);

    const {
        userData // user data
    } = useContext(AppContext);

    // para abrir o cerrar el modal 
    // to open or close modal 
    const [isOpen, setIsOpen] = useState(false)

    const [emptyState, setEmptyState] = useState(false)
    // variable para definir si el buscador tiene resultados o no 
    // var for search result 
    const [noCoincidence, setNoCoincidence] = useState(false)

    // para pasarle la data de la transaccion al modal por medio de props
    // to pass by props, the transaction data to handle on modal 
    const [transData, setTransData] = useState({})

    // variable que almacena las transacciones filtradas 
    // var for filters transactions 
    const [transactionsFilter, setTransactionsFilter] = useState([])
    // variable que almacena las transacciones no de la primera carga 
    // var for first get transactions
    const [transactions, setTransactions] = useState([])

    // funcion que consulta la lista de trasacciones rechazadas
    // function for get all rejected transactions 
    useEffect(async () => {
        setApiTypeSource(source)
        const trans = await getRejectedTransactions('wink', apiTypeSource)
        console.log("🚀 ~ file: RejectedTransactions.jsx:58 ~ useEffect ~ wink:", true)
        if (trans) {
            if (trans.length === 0) setEmptyState(true)
            else setEmptyState(false)
            setTransactions(trans)
        }
    }, [])

    useEffect(() => {
        (loading || loadingSearch) ? setShowBtnCsv(false) : setShowBtnCsv(true)
    }, [loading, loadingSearch])

    // funcion para manejar el cerrado del modal 
    // to handle close modal 
    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <RejectedModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                data={transData}
                handleClose={handleClose}
                setTransactions={setTransactions}
                transactionsFilter={transactionsFilter}
                setTransactionsFilter={setTransactionsFilter}
                isRejected={true} />
            <div className="trans-container">
                {
                    userData.roles &&
                    (userData.roles.includes('moderator') || userData.roles.includes('administrator')) &&
                    <div className="trans-buscador-container">
                        {/* 
                    componente de buscador que recibe funciones y variables para su funcionamiento 
                    search engine component, it recives variables and func to work
                    */}
                        <SearchEngine
                            // functions 
                            getTransactions={getRejectedTransactions}
                            setNoCoincidence={setNoCoincidence}
                            setTransactionsFilter={setTransactionsFilter}
                            setTransactions={setTransactions}
                            // variables 
                            history={false}
                            processed={false}
                            setEmptyState={setEmptyState}
                            vertical="Wink"
                        />
                        {
                            noCoincidence && <p className="no-coincidence">No se encontraron coincidencias...</p>
                        }
                    </div>
                }
                {/* scv button conditional  */}
                {
                    showBtnCsv &&
                    <div className="export-btn-container">
                        <button className="btn-yummy" style={{ marginLeft: 'auto' }} onClick={onBtnExport}>Exportar CSV</button>
                    </div>
                }
                <div className="trans-table-container panel panel-default ag-theme-alpine">
                    <TransactionTable
                        isRejected={true}
                        trans={transactionsFilter.length === 0 ? transactions : transactionsFilter}
                        setIsOpen={setIsOpen}
                        setTransData={setTransData}
                    />
                </div>
            </div>
        </>
    );
};

export default RejectedTransactions;
