import React, {useState, useEffect} from 'react'
import {ReadSvg} from '../../assets/permissions/read'
import {EditSvg} from '../../assets/permissions/edit'
import {RevertSvg} from '../../assets/permissions/revert'
import {AdminSvg} from '../../assets/permissions/admin'
import Read from '../../assets/permissions/read.svg'
import Edit from '../../assets/permissions/edit.svg'
import Revert from '../../assets/permissions/revert.svg'
import Admin from '../../assets/permissions/admin.svg'
import { HoverComponent } from './permissionHoverComponent'

import './styles.css'

export const PermissionsComponent = (props) => {

    const roles = (props.colDef.field == 'roles_wink')? (props.data.roles_wink?.length)? props.data.roles_wink : [] : props.data.roles

    return (
        <div className="permissions-container">
            <div className="permissions-icon read">
                <HoverComponent classType="permission-poppover read-hover" img={Read} type="Visitante" active={true} />
                <ReadSvg color="#4FC0FF" />
            </div>
            <div className="permissions-icon edit">
                <HoverComponent classType="permission-poppover edit-hover" img={Edit} type="Usuario" active={roles.includes("user") ? true : false} />
                <EditSvg color={roles.includes("user") ? "#FFCE4F" : "var(--mid-gray)"} />
            </div>
            <div className="permissions-icon moderator">
                <HoverComponent classType="permission-poppover moderator-hover" img={Revert} type="Moderador" active={roles.includes("moderator") ? true : false} />
                <RevertSvg color={roles.includes("moderator") ? "var(--offline-color)" : "var(--mid-gray)"} />
            </div>
            <div className="permissions-icon admin">
            <HoverComponent classType="permission-poppover admin-hover" img={Admin} type="Administrador" active={roles.includes("administrator") ? true : false} />
                <AdminSvg color={roles.includes("administrator") ? "var(--online-color)" : "var(--mid-gray)"} />
            </div>
        </div>
    )
}