import React, {
    forwardRef,
    useState,
    useRef,
    useImperativeHandle,
    useContext
  } from 'react';
import TransContext from "../../context/trans/TransContext";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DatePicker from 'react-datepicker';
  
  const CustomDateComponent = forwardRef((props, ref) => {

    // este contexto sirve para definir el rango de fechas para filtrar en la columna segun el buscador
    // this context define the range between start and end dates search engine for column filter 
    // const { mindate } = useContext(TransContext);
    const [date, setDate] = useState(null);
    const refDatePicker = useRef();
    const refInput = useRef();
  
    const onDateChanged = selectedDate => {
      setDate(selectedDate);
      // devuelve la fecha a ag grid 
      // returns date to ag grid 
      props.onDateChanged();
    };
  
    // funcion que necesita aggrid para funcionar 
    // necesary to ag grid 
    useImperativeHandle(ref, () => ({
      getDate() {
        return date;
      },
  
      setDate(date) {
        setDate(date);
      }
    }));

    // limpia la fecha y llama la funcion onDateChanged con un string vacio para remover el filto 
    // clear the date and call onDateChanged with emptystring to remove filters 
    const clearDate = () => {
        setDate(null)
        onDateChanged('')
    }
  
    return (
      <div ref={refInput} className="ag-input-wrapper datepicker-container">
        <DatePicker
          className="datepicker-component"
          ref={refDatePicker}
          portalId="root"
          dateFormat="dd/MM/yyyy"
          popperClassName="ag-custom-component-popup"
          selected={date}
          onChange={onDateChanged}
          // minDate={mindate}
          // maxDate={maxDate}
        />
        <HighlightOffIcon className="clear-btn" style={{width: 18}} onClick={()=> clearDate()} />
      </div>
    );
  });
  
  export default CustomDateComponent;