import React, { useState, useEffect, useContext } from "react";
import AdminContext from "./AdminContext";
import AppContext from "../app/AppContext";
import { useHistory } from "react-router-dom";
import { sweetalert, sweetalertLogout } from '../../helpers/SweetAlert'


const AdminState = ({ children }) => {

  const { logout } = useContext(AppContext)

  const { token } = JSON.parse(localStorage.getItem("dataLogin"));

  const history = useHistory()
  // loading para 
  // loader
  const [loading, setLoading] = useState(false)

  // evita que se pueda ingresar desde un dispositivo movil 
  // avoid to sign in on mobile 
  useEffect(()=> {
    window.screen.width < 868 && history.push("/not-responsive")
  },[])

  const getUsers = async () => {
    setLoading(true)
    // console.log('token', token)
      try {
        const res = await fetch(`${process.env.REACT_APP_API}/api/users/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // "x-authorization": process.env.REACT_APP_ZELLE_BEARER
              "X-Access-Token": token
            },
          })
          const response = await res.json()
          if(res.status >= 400 && res.status < 500) {
            setLoading(false)
            sweetalert('Lo siento :(', 'has sido desconectado, porfavor ingresa de nuevo', 'error')
            logout()
        }else{
          const respuesta = response.users
          const users = respuesta.map((user)=>{
            const obj = {
              ...user,
              id: user._id.$oid
            }
            return obj
          })
          setLoading(false)
          // console.log(users)
          return users
        }
      }catch(e){
        console.log(e)
        setLoading(false)
      }
  }

  const UpdateUSerRoles = async (id, added, removed, vertical) => {
    try {
      setLoading(true)
      const data = {
        roles: added,
        removed_roles: removed,
        vertical
      }
      const res = await fetch(`${process.env.REACT_APP_API}/api/users/update/roles/${id}`, {
          method: 'PUT',
          headers: {
              "Content-Type": "application/json",
              // "X-Authorization": process.env.REACT_APP_ZELLE_BEARER
              "X-Access-Token": token
            },
            body: JSON.stringify(data)
      })
      const response = await res.json()
      setLoading(false)
      return response
    }catch(e){
      setLoading(false)
      console.log(e)
    }
  }

  const createUser = async (name, email, roles) => {
    try {
      const data = {
        full_name: name,
        email,
        roles
      }
      setLoading(true)
      const res = await fetch(`${process.env.REACT_APP_API}/api/users/register`, {
          method: 'POST',
          headers: {
              "Content-Type": "application/json",
              // "X-Authorization": process.env.REACT_APP_ZELLE_BEARER
              "X-Access-Token": token
            },
            body: JSON.stringify(data)
      })
      const response = await res.json()
      setLoading(false)
      return response
    }catch(e){
      setLoading(false)
      console.log(e)
    }
  }

  const deleteUserData = async (id) => {
    try {
      const data = {

      }
      setLoading(true)
      const res = await fetch(`${process.env.REACT_APP_API}/api/users/delete/${id}`, {
          method: 'DELETE',
          headers: {
              "Content-Type": "application/json",
              // "X-Authorization": process.env.REACT_APP_ZELLE_BEARER
              "X-Access-Token": token
            },
            body: JSON.stringify(data)
      })
      const response = await res.json()
      setLoading(false)
      return response
    }catch(e){
      setLoading(false)
      console.log(e)
    }
  }

  const updateUserInfo = async (data, id) => {
    try {
      setLoading(true)
      const res = await fetch(`${process.env.REACT_APP_API}/api/users/update/${id}`, {
          method: 'PUT',
          headers: {
              "Content-Type": "application/json",
              // "X-Authorization": process.env.REACT_APP_ZELLE_BEARER
              "X-Access-Token": token
            },
            body: JSON.stringify(data)
      })
      const response = await res.json()
      setLoading(false)
      return response
    }catch(e){
      setLoading(false)
      console.log(e)
    }
  }

  const getWithdrawalsUserList = async (data) => {
    try {
      setLoading(true)
      
      let path = '/api/withdrawals/'
      if (data.vertical == 'all') path = '/api/withdrawals/all'

      const res = await fetch(`${process.env.REACT_APP_API}${path}`, {
          method: 'POST',
          headers: {
              "Content-Type": "application/json",
              // "X-Authorization": process.env.REACT_APP_ZELLE_BEARER
              "X-Access-Token": token
            },
            body: JSON.stringify(data)
      })
      const response = await res.json()
      setLoading(false)
      return response
    }catch(e){
      setLoading(false)
      console.log(e)
    }
  }


  return (
    <AdminContext.Provider
      value={{
        loading, setLoading,
        getUsers,
        UpdateUSerRoles,
        createUser,
        deleteUserData,
        updateUserInfo,
        getWithdrawalsUserList
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminState;
