import React, { useState, useEffect } from 'react';
import AppContext from './AppContext';
import { useHistory } from 'react-router-dom';
import { sweetalert, sweetalertLogout } from '../../helpers/SweetAlert';

const AppState = ({ children }) => {
  const history = useHistory();
  // data del usuario para el login
  // user data for login
  const [datalogin, setDataLogin] = useState(null);
  // loading para el login
  // login loader
  const [loading, setLoading] = useState(false);

  // roles para administrar acciones del usuario
  // user roles
  const [userData, sertUserData] = useState(
    JSON.parse(localStorage.getItem('dataLogin'))
  );

  // id de google para usar el boton de login con google
  // google id for google authentication
  const clientGoogleId = process.env.REACT_APP_GOOGLE_AUTH;

  useEffect(() => {
    _isAuth();
    sertUserData({
      ...userData, 
        // roles: ["administrator", "user", "moderator"],
        // roles: [],
        // roles_wink: ["administrator", "user", "moderator"]
        // roles_wink: []
    })
  }, []);

  // evita que se pueda ingresar desde un dispositivo movil
  // avoid to sign in on mobile
  useEffect(() => {
    window.screen.width < 868 && history.push('/not-responsive');
  }, []);

  // funcion para tomar la data del localstorage
  // func to get local storage data
  const _isAuth = () => {
    let dataLocal = JSON.parse(localStorage.getItem('dataLogin'));
    if (dataLocal) {
      setDataLogin(dataLocal);
    }
  };

  // almacena la data en el localStorage luego de recibir la data del usuario
  // store the data on localstorage after getting google user data
  const signIn = (token, name, email, user) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API}/api/authentication/login`, {
  		method: 'POST',
  		headers: {
  			"Content-Type": "application/json"
  		},
  		body: JSON.stringify({
  			token
  		})
  	})
  	.then(res => {
  		res.json().then(response => {
        console.log("🚀 ~ file: AppState.jsx:60 ~ res.json ~ response:", response)
        if(res.status === 401 || (response.roles.length === 0)){
          setLoading(false)
          sweetalert('Alto!', 'No estas autorizado, por favor solicita permisos para ingresar.', 'error')
        }else{
          const dataLogin = {
            email: email,
            token: response.token,
            roles: response.roles,
            roles_wink: response.roles_wink || [],
            name: name,
            user
          };
          localStorage.setItem("dataLogin", JSON.stringify(dataLogin));
          setLoading(false)
          window.location.pathname = "/";
          setDataLogin(dataLogin)
        }
  		})
  	})
  	.catch(err => {
      setLoading(false)
      console.log(err)
    });
  }

  // const signIn = () => {
  //   const dataLogin = {
  //     email: 'john.doe@yummysuperapp.com',
  //     name: 'John Doe',
  //     roles: ['administrator', 'user', 'moderator'],
  //     roles_wink: [
  //       "user",
  //       "visitor" ,
  //       "administrator",
  //       "moderator",
  //     ],
  //     token: "",
  //     user: {
  //       first_name: 'John',
  //       last_name: 'Doe',
  //       user_img:
  //         'https://lh3.googleusercontent.com/a/AATXAJwTlsR025iYTmxLurApq4ONPb05XeA83ILt6F_M=s96-c',
  //     },
  //   };
  //   localStorage.setItem('dataLogin', JSON.stringify(dataLogin));
  //   setLoading(false);
  //   window.location.pathname = '/';
  //   setDataLogin(dataLogin);
  // };

  // elimina la dat del localStorage
  // remove localStorage data
  const logout = () => {
    history.push('/login');
    localStorage.clear();
    setDataLogin(null);
  };

  // pide verificacion para cerrar sesion
  // for request the log out by modal
  const requestLogout = () => {
    sweetalertLogout('Quieres cerrar sesion?', '', 'warning', logout);
  };

  return (
    <AppContext.Provider
      value={{
        datalogin,
        signIn,
        logout,
        clientGoogleId,
        loading,
        requestLogout,
        userData,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppState;
