import React from "react";
import TransState from '../../context/trans/TransState'
import TransactionsList from '../../components/trans/wink/TransactionsList'
import Menu from '../../components/menu/Menu'

const ZelleListScreen = () => {
  return (
    <>
      <TransState>
          <Menu />
          <TransactionsList />
      </TransState>
    </>
  );
};

export default ZelleListScreen;
