import React from 'react'
import '../../Styles/styles.css'
import Revert from '../../assets/permissions/revert.svg'

export const IdComponent = (props) => {
    const checkIfProcessed = (data) => {
        if ("reversed_by" in data && !data.processed) return "trnas-process reversed" 
        if (data.processed || !data.bank_error) return "trnas-process processed"
        return "trnas-process not-processed"
    }

    return (
        <p className={checkIfProcessed(props.data)}>
            {props.data.auto_incremental_id}
                {
                    props.data.reversed_by ? 
                    <span className={props.data.processed ? "status-poppover processed-span" : "status-poppover not-processed-span"} style={{display: 'flex', alignItems: 'center', right: "-141px"}}>
                        <img src={Revert}/> Revertida
                    </span>
                    :
                    <span className={(props.data.processed || !props.data.bank_error) ? "status-poppover processed-span" : "status-poppover not-processed-span"}>
                        {props.data.processed && 'Procesada'}
                        {props.data.processed === false && 'Pendiente'}
                        {props.data.bank_error && 'Error del banco' }
                        {props.data.bank_error === false && 'Procesada' }
                    </span>
                }
        </p>
    )
}
  