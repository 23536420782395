import React from 'react'

const CustomSvg = ({ color }) => {
    return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9.25" stroke={color} stroke-width="1.5"/>
    <rect x="8" y="10" width="2" height="2" transform="rotate(-180 8 10)" fill={color}/>
    <rect x="18" y="10" width="8" height="2" transform="rotate(-180 18 10)" fill={color}/>
    <rect x="8" y="13" width="2" height="2" transform="rotate(-180 8 13)" fill={color}/>
    <rect x="18" y="13" width="8" height="2" transform="rotate(-180 18 13)" fill={color}/>
    <rect x="8" y="16" width="2" height="2" transform="rotate(-180 8 16)" fill={color}/>
    <rect x="18" y="16" width="8" height="2" transform="rotate(-180 18 16)" fill={color}/>
    </svg>
    )
}

export default CustomSvg