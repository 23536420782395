import React, { useEffect } from 'react'
import './loader.css'
import lottie from "lottie-web";
import GreenLoader from './loader-green.json'

export const Loader = ({color}) => {

    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#react-logo"),
          animationData: GreenLoader
        });
      }, []);

    return(
        <div className='loader-container'>
            <div id="react-logo" />
        </div>
    )
}