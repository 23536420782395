import React from "react";
import TransState from '../../context/trans/TransState'
import TransactionsList from '../../components/trans/yummy/TransactionsList'
import Menu from '../../components/menu/Menu'

const ZelleListScreen = ({ source = 'Debit' }) => {
  return (
    <>
      <TransState>
        <Menu />
        <TransactionsList source={source} />
      </TransState>
    </>
  );
};

export default ZelleListScreen;
