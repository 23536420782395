import React, {useEffect, useState, useContext} from 'react'
import AppContext from '../../context/app/AppContext'
import "./styles.css"
import LogoutLogo from '../../assets/logout.svg'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {rolesCases} from './user-permissions'

export const UserInfo = ({data, requestLogout, setShow}) => {

    const { userData } = useContext(AppContext)

    const [rolesYummy, setRolesYummy] = useState([])
    const [rolesWink, setRolesWink] = useState([])
    // const userData = JSON.parse(localStorage.getItem("dataLogin"));
    const rolesSelector = {
        "administrator": (rolesArr) => rolesArr.push("Administrador"),
        "moderator": (rolesArr) => rolesArr.push("Moderador"),
        "user": (rolesArr) => rolesArr.push("Usuario"), 
        "visitor": (rolesArr) => rolesArr.push("Visitante")
    }
    
    useEffect(()=> {
        handleRoles(userData.roles, setRolesYummy)
        handleRoles(userData.roles_wink, setRolesWink)
    },[])

    const handleRoles = (data, func) => {
        const roles = []
        const userRoles = []
        for (const role of data) {
            rolesSelector[role](userRoles)
        }
        for (const rol in rolesCases){
            if (userRoles.includes(rolesCases[rol]['name'])) {
                roles.push(rolesCases[rol])
                console.log(rolesCases[rol])
            }
        }
        func(roles)
    }



    return (
        <div className="user-info-container none">
            <HighlightOffIcon className="close-btn" onClick={() => setShow(false)} />
            <p className="user-key">Correo de usuario</p>
            <p className="user-info-text" style={{
                borderBottom: '1px solid var(--light-gray)',
                paddingBottom: '10px'
             }}>{data.email}</p>
            {
                rolesYummy.length > 0 &&
                <>
                    <p className="user-info-text">Permisos Yummy</p>
                    {
                        rolesYummy &&
                        rolesYummy.map((rol, i) => {
                            return (
                                <p key={i} className="user-rol"><img className="permission-user-img" src={rol.img} />{rol.name}</p>
                            )
                        })
                    }
                </>
            }
            <div style={{
                margin: '10px 0',
                borderTop: '1px solid var(--light-gray)',
                width: '100%'
            }} />
            {
                rolesWink.length > 0 &&
                <>
                    <p className="user-info-text">Permisos Wink</p>
                    {
                        rolesWink &&
                        rolesWink.map((rol, i) => {
                            return (
                                <p key={i} className="user-rol"><img className="permission-user-img" src={rol.img} />{rol.name}</p>
                            )
                        })
                    }
                </>
            }
            <div className="logout-seccion" onClick={requestLogout}>
                <p>Cerrar sesion</p>
                <img className="logout-logo" src={LogoutLogo} />
            </div>
        </div>
    )
}