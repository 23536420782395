export const banks = {
    "zelle": {assetRoute:  require('../../assets/banks/zelle.png').default, name: "Zelle"},
    "bancumbre": {assetRoute: require('../../assets/banks/bancumbre.png').default, name: "Bancumbre"},
    "pipol": {assetRoute: require('../../assets/banks/pipol_pay.png').default, name: "Pipol Päy"},
    "venmo": {assetRoute: require('../../assets/banks/venmo.png').default, name: "Venmo"},
    "reserve": {assetRoute: require('../../assets/banks/reserve.png').default, name: "Reserve"},
    "binance": {assetRoute: require('../../assets/banks/binance.png').default, name: "Binance"},
    "paypal": {assetRoute: require('../../assets/banks/paypal.png').default, name: "Paypal"},
    "card": {assetRoute: require('../../assets/banks/card.png').default, name: "Tarjeta"},
    "0156": {assetRoute: require('../../assets/banks/0156.png').default, name: "100% banco"},
    "0196": {assetRoute: require('../../assets/banks/0196.png').default, name: "ABN AMRO Bank"},
    "0171": {assetRoute: require('../../assets/banks/0171.png').default, name: "Banco Activo"},
    "0166": {assetRoute: require('../../assets/banks/0166.png').default, name: "Banco Agricola"},
    "0175": {assetRoute: require('../../assets/banks/0175.png').default, name: "Banco Bicentenario"},
    "0128": {assetRoute: require('../../assets/banks/0128.png').default, name: "Banco Caroni"},
    "0006": {assetRoute: require('../../assets/banks/0006.png').default, name: "Banco de Coro"},
    "0164": {assetRoute: require('../../assets/banks/0164.png').default, name: "Banco de desarrollo de microempresarios"},
    "0102": {assetRoute: require('../../assets/banks/0102.png').default, name: "Banco de Venezuela"},
    "0114": {assetRoute: require('../../assets/banks/0114.png').default, name: "Banco del Caribe"},
    "0149": {assetRoute: require('../../assets/banks/0149.png').default, name: "Banco del Pueblo Soberano"},
    "0163": {assetRoute: require('../../assets/banks/0163.png').default, name: "Banco del Tesoro"},
    "0176": {assetRoute: require('../../assets/banks/0176.png').default, name: "Banco de Espiritu Santo"},
    "0115": {assetRoute: require('../../assets/banks/0115.png').default, name: "Banco Exterior"},
    "0151": {assetRoute: require('../../assets/banks/0151.png').default, name: "Banco Fondo Comun"},
    "0008": {assetRoute: require('../../assets/banks/0008.png').default, name: "Banco Guayana"},
    "0003": {assetRoute: require('../../assets/banks/0003.png').default, name: "Banco Industrial de Venezuela"},
    "0173": {assetRoute: require('../../assets/banks/0173.png').default, name: "Banco Internacional de Desarrollo"},
    "0105": {assetRoute: require('../../assets/banks/0105.png').default, name: "Mercantil"},
    "0191": {assetRoute: require('../../assets/banks/0191.png').default, name: "Banco Nacional de Credito"},
    "0116": {assetRoute: require('../../assets/banks/0116.png').default, name: "Banco Occidental de Descuento"},
    "0138": {assetRoute: require('../../assets/banks/0138.png').default, name: "Banco Plaza"},
    "0108": {assetRoute: require('../../assets/banks/0108.png').default, name: "Banco Provincial"},
    "0137": {assetRoute: require('../../assets/banks/0137.png').default, name: "Banco SOFITASA"},
    "0104": {assetRoute: require('../../assets/banks/0104.png').default, name: "Banco Venezolano de Credito"},
    "0172": {assetRoute: require('../../assets/banks/0172.png').default, name: "BANCAMIGA"},
    "0145": {assetRoute: require('../../assets/banks/0145.png').default, name: "BANCOEX"},
    "0168": {assetRoute: require('../../assets/banks/0168.png').default, name: "BANCRECER"},
    "banesco": {assetRoute: require('../../assets/banks/0134.png').default, name: "BANESCO"},
    "0177": {assetRoute: require('../../assets/banks/0177.png').default, name: "BANFANB"},
    "0007": {assetRoute: require('../../assets/banks/0007.png').default, name: "BANFOANDES"},
    "0146": {assetRoute: require('../../assets/banks/0146.png').default, name: "BANGENTE"},
    "0174": {assetRoute: require('../../assets/banks/0174.png').default, name: "BANPLUS"},
    "0190": {assetRoute: require('../../assets/banks/0190.png').default, name: "CITIBANK"},
    "0121": {assetRoute: require('../../assets/banks/0121.png').default, name: "CORP Banca"},
    "0157": {assetRoute: require('../../assets/banks/0157.png').default, name: "DELSUR Banco Universal"},
    "0601": {assetRoute: require('../../assets/banks/0601.png').default, name: "Instituto Municipal de Credito Popular"},
    "0169": {assetRoute: require('../../assets/banks/0169.png').default, name: "MIBANCO Banco de Desarrollo"},
    "0016": {assetRoute: require('../../assets/banks/0016.png').default, name: "Banco Central Hispanoamericano, S.A"}
}