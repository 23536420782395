import React from 'react'
import '../../Styles/styles.css'

export const IdStatusComponent = (props) => {
    const { online, auto_incremental_id } = props.data

    return (
        <p className="id-status-container">
            <div className={ online ? "status-circle online" : "status-circle offline"}></div>
            {auto_incremental_id}
            <span className={online ? "status-poppover processed-span" : "status-poppover not-processed-span"}>{online ? 'Habilitado' : 'Deshabilitado' }</span>
        </p>
    )
  }
  