import React, { useState, useContext, useEffect } from 'react';
import TransContext from './TransContext';
import moment from 'moment-timezone';
import AppContext from '../app/AppContext';
import { toast } from 'react-toastify';
import { sweetalert } from '../../helpers/SweetAlert';
import axios from 'axios';

const TransState = ({ children }) => {
  const { logout } = useContext(AppContext);
  const [user, setSelectedUser] = useState({});

  const date = new Date();

  // loaders
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isCorporate, setIsCorporate] = useState(false);
  const [apiTypeSource, setApiTypeSource] = useState('Debit');

  // gri api context
  const [gridApiContext, setGridApiContext] = useState(null);
  // to show scv button
  const [showBtnCsv, setShowBtnCsv] = useState(false);
  // to count the rows on tables
  const [rowCount, setRowsCount] = useState(null);

  // para obtener la data del login en la primera carga
  // to get data login on first charge
  const { token, email, name } = JSON.parse(localStorage.getItem('dataLogin'));

  // para los datepicker de los filtros de las columnas
  // for date picker colunm filters
  const [mindate, setMinDate] = useState(
    new Date(date.getTime() - 6 * 24 * 60 * 60 * 1000)
  );
  const [maxDate, setMaxDate] = useState(
    new Date(date.getTime() - 0 * 24 * 60 * 60 * 1000)
  );

  // funciones para formatear las horas a string
  // formatter dates functions
  const dateValidate = (trans, date) => {
    const data =
      trans.platform === 'PAGO MOVIL'
        ? moment(date).tz('America/Caracas').format('DD/MM/yyyy')
        : moment(date).format('DD/MM/yyyy');
    return data;
  };

  const timValidate = (trans, date) => {
    const data = trans.hour ? trans.hour : moment(date).format('HH:mm');
    return data;
  };

  // para obtener las transacciones rezagadas
  // to get rejected transactions
  const getRejectedTransactions = async (isWink, source) => {
    console.log("🚀 ~ file: TransState.jsx:58 ~ getRejectedTransactions ~ isWink:", isWink)
    setLoading(true);
    try {
      const url = isWink
        ? '/api/payments/v2/differed/Wink'
        : '/api/payments/v2/differed'
      const res = await fetch(
        `${process.env.REACT_APP_API}${url}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // "x-authorization": process.env.REACT_APP_ZELLE_BEARER
            'X-Access-Token': token,
            'X-Payment-Source': source ?? 'Debit'
          },
        }
      );
      const response = await res.json();

      // if status is 401 the app will logout the user
      if (res.status === 401) {
        //TO DO: este codigo se repite varias veces, debe convertirse en una funcion
        setLoading(false);
        sweetalert(
          'Lo siento :(',
          'has sido desconectado, porfavor ingresa de nuevo',
          'error'
        );
        logout();
      } else if (res.status === 400) {
        sweetalert('Aviso', response.message, 'warning');
      } else {
        const trans = response.transactions.map((tran) => {
          const date = tran.date.$date;
          const obj = {
            ...tran,
            amount: tran.amount,
            amountBSD: tran.amountBSD ? tran.amountBSD : '',
            date: dateValidate(tran, date),
            time: timValidate(tran, date),
            status: tran.processed ? 'Procesada' : 'Rezagada',
          };
          return obj;
        });
        setLoading(false);
        return trans;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserDelivery = async (phone) => {
    try {
      setLoadingProcess(true);
      phone = phone.replace('+', '');
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/payments/delivery/user/+${phone}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Token': token,
          },
        }
      );
      setLoadingProcess(false);
      return res;
    } catch (e) {
      console.error(e);
    }
  };

  // GET USERS DINGO
  const getUsersDingo = async (option, keyword) => {
    setLoadingProcess(true);
    let data = {
      [option]: keyword,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/payments/dingo/users`,
        data,
        {
          headers: {
            'content-Type': 'application/json',
            'X-Access-Token': token,
          },
        }
      );
      setLoadingProcess(false);
      if (res.data.success) {
        return res.data.users;
      }
    } catch (e) {
      console.log(e);
    }
  };

  // GET USERS rides
  const getUsersRides = async (option, value) => {
    try {
      setLoadingProcess(true);
      const data = {
        [option]: value,
        isCorporates: isCorporate,
      };
      let path = 'api/payments/rides/user';
      const res = await axios.post(
        `${process.env.REACT_APP_API}/${path}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Token': token,
          },
        }
      );
      setLoadingProcess(false);
      if (res.data.success) {
        return res.data.users;
      }
    } catch (error) {
      console.error(error);
      return {
        data: [],
      };
    }
  };

  // GET USERS yummers
  const getUsersYummers = async (doc) => {
    try {
      setLoadingProcess(true);
      const data = {
        nro_doc: doc
      };
      let path = 'users/driver_verification';
      const res = await axios.post(
        `${process.env.REACT_APP_API_FINANCE}/${path}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-api-key": process.env.REACT_APP_API_KEY_FINANCE,
          },
        }
      );
      setLoadingProcess(false);
      if (res.data.success) {
        return res.data.yummer;
      }
    } catch (error) {
      console.error(error);
      setLoadingProcess(false);
      return []
    }
  };

  // para obtener las transacciones en un rango de fechas
  // to get transactions on a date range
  const seacrhTransaction = async (startDate, endDate, processed, history, vertical = null, source) => {
    try {
      setLoadingSearch(true);
      const data = {
        start: startDate,
        end: endDate,
        processed,
      };
      const url = vertical == 'Wink'
        ? '/api/payments/v2/filter/Wink'
        : '/api/payments/v2/filter'
      const res = await fetch(
        `${process.env.REACT_APP_API}${url}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // "X-Authorization": process.env.REACT_APP_ZELLE_BEARER
            'X-Access-Token': token,
            'X-Payment-Source': source ?? 'Debit'
          },
          body: JSON.stringify(data),
        }
      );
      const response = await res.json();
      if (!response.success) {
        sweetalert('Alto!', response.message, 'error');
        setLoadingSearch(false);
        return '';
      }
      const trans = response.transactions.map((tran) => {
        const date = tran.date.$date;
        const obj = {
          ...tran,
          amount: tran.amount,
          amountBSD: tran.amountBSD ? tran.amountBSD : '',
          date: dateValidate(tran, date),
          time: timValidate(tran, date),
          status: tran.processed ? 'Procesada' : 'Rezagada',
        };
        return obj;
      });
      setLoadingSearch(false);
      return trans;
    } catch (e) {
      console.log(e);
    }
  };

  // para obtener la lista de transacciones
  // to get transactions list
  const getTransactionsList = async (vertical = null, source) => {
    try {
      setLoading(true);
      const url = vertical == 'Wink'
        ? '/api/payments/v2/history/Wink'
        : '/api/payments/v2/history'
      const res = await fetch(
        `${process.env.REACT_APP_API}${url}?processed=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // "x-authorization": process.env.REACT_APP_ZELLE_BEARER
            'X-Access-Token': token,
            'X-Payment-Source': source ?? 'Debit'
          },
        }
      );
      const response = await res.json();

      // if status is 401 the app will logout the user
      if (res.status === 401) {
        setLoading(false);
        sweetalert(
          'Lo siento :(',
          'has sido desconectado, porfavor ingresa de nuevo',
          'error'
        );
        logout();
      } else {
        const trans = response.transactions.map((tran) => {
          const date = tran.date.$date;
          const obj = {
            ...tran,
            amount: tran.amount,
            amountBSD: tran.amountBSD ? tran.amountBSD : '',
            date: dateValidate(tran, date),
            time: timValidate(tran, date),
            status: tran.processed ? 'Procesada' : 'Rezagada',
          };
          return obj;
        });
        setLoading(false);
        return trans;
      }
    } catch (e) {
      console.log(e);
    }
  };

  // para obtener las transacciones en un rango de fechas
  // to get transactions on a date range
  // const seacrhTransaction = async (startDate, endDate, history) => {
  // 	try {
  // 		setLoadingSearch(true)
  // 		const data = {
  // 			start: startDate,
  // 			end: endDate,
  // 			history
  // 		}
  // 		const res = await fetch(`${process.env.REACT_APP_API}/api/payments/v2/filter`, {
  // 			method: 'POST',
  // 			headers: {
  // 				"Content-Type": "application/json",
  // "X-Authorization": process.env.REACT_APP_ZELLE_BEARER
  // "X-Access-Token": token
  // 			  },
  // 			  body: JSON.stringify(data)
  // 		})
  // 		const response = await res.json()
  // 		const trans = response.transactions.map(tran => {
  // 			const date = tran.date.$date
  // 				const obj = {
  // 					...tran,
  // 					amount: tran.amount,
  // 					amountBSD: tran.amountBSD ? tran.amountBSD : '',
  // 					date: dateValidate(tran, date),
  // 					time: timValidate(tran, date),
  // 					status: tran.processed ? 'Procesada' : 'Rezagada'
  // 				}
  // 			return obj
  // 		})
  // 		setLoadingSearch(false)
  // 		return trans
  // 	}catch(e){
  // 		console.log(e)
  // 	}
  // }


  const processRides = async (data) => {
    setLoadingProcess(true)
    try {
      let operation_detail_code = 0

      const operationCodeOptions = [
        { name: "ZELLE", code: "140" },
        { name: "STRIPE", code: "150" },
        { name: "PAGO MOVIL", code: "100" },
      ];

      operation_detail_code = operationCodeOptions.find(opt => opt.name == data.platform).code;

      let path = 'api/v2/requests/update-balance-scrap';

      const body = {
        amount: data.amount,
        userType: isCorporate ? "5" : "10",
        paymentMethod: data.platform,
        auto_incremental_id: data.auto_incremental_id,
        operation_details_code: operation_detail_code,
        _id: user._id
      }

      if (data.user_type == "DRIVER" && data.platform == "PAGO MOVIL") {
        operation_detail_code = "105";
        path = "api/v2/providers/wallet-amount-scrap"
        body.userType = "11"
      }


      await fetch(`${process.env.REACT_APP_API_RIDES_URL}/${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/JSON',
          Authorization: `Bearer ${process.env.REACT_APP_API_RIDES}`,
        },
        body: JSON.stringify(body),
      });

      setLoadingProcess(false);

      const payload = {
        vertical: isCorporate ? "YUMMY RIDES CORPORATES" : "YUMMY RIDES",
        withoutRecharge: true,
        associatedTo: user.phone,
        processedBy: email,
      };

      const res = await processDiffered(payload, data._id.$oid);
      setLoadingProcess(true)

      if (res.processed) return { success: true }
      else return { success: false }
    } catch (error) {
      setLoadingProcess(true)
      console.log("processRides ~ error", error)
    }
  };

  const processDingo = async (data) => {
    setLoadingProcess(true)
    try {
      let payload = {
        phone: data.phoneNumber,
        amount: data._transaction_info.amount,
        paymentMethod: data._transaction_info.platform,
        prefix: data._transaction_info.name.slice(0, 2) === 'ML' ? 'ml' : '',
        vertical: data.platform,
        processedBy: email,
        associatedTo: data.phoneNumber,
      };

      const res = await fetch(
        `${process.env.REACT_APP_API}/api/payments/dingo/validation/${data._id.$oid}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Token': token,
          },
          body: JSON.stringify(payload),
        }
      );

      setLoadingProcess(false);
      return res.json();
    } catch (e) {
      setLoadingProcess(false);
      return e.response.data;
    }
  };

  const processDiffered = async (data, transId) => {
    const res = await fetch(
      `${process.env.REACT_APP_API}/api/payments/differed/${transId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': token
        },
        body: JSON.stringify(data),
      }
    );

    const response = await res.json();

    // if status is 401 the app will logout the user
    if (res.status === 401) {
      setLoadingProcess(false);
      sweetalert(
        'Lo siento :(',
        'has sido desconectado, porfavor ingresa de nuevo',
        'error'
      );
      logout();
    } else {
      setLoadingProcess(false);
      return response;
    }
  };

  const processYummer = async (data) => {
    setLoadingProcess(true)
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/payments/delivery/validation/yummer/${data._id.$oid}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Token': token,
            'X-Payment-Source': 'Debit'
          },
          body: JSON.stringify({
            "vertical": "Yummy Delivery",
            "uniqueID": data.auto_incremental_id,
            "amount": data.amount,
            "processedBy": email,
            "associatedTo": data.num_doc
          }),
        }
      );
      const response = await res.json();
      setLoadingProcess(false)
      if (response.processed) return { success: true }
      else return { success: false }
    } catch (error) {
      setLoadingProcess(false)
      console.log("🚀 ~ file: TransState.jsx:466 ~ processYummer ~ error", error)
    }
  }

  // para procesar una transaccion rezagada y recargar la wallet del usuario
  // to process rejected transaction and recharge user wallet
  const processTransactionHugo = async (trans, phone, platform) => {
    try {
      const objectID = trans._id.$oid;
      setLoadingProcess(true);
      const data = {
        vertical: platform,
        uniqueID: trans.auto_incremental_id,
        phone,
        associatedTo: phone,
        processedBy: email,
        amount: trans.amount,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/payments/delivery/validation/${objectID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Token': token,
            'X-Payment-Source': 'Debit'
          },
          body: JSON.stringify(data),
        }
      );

      const response = await res.json();

      // if status is 401 the app will logout the user
      if (res.status === 401) {
        setLoadingProcess(false);
        sweetalert(
          'Lo siento :(',
          'has sido desconectado, porfavor ingresa de nuevo',
          'error'
        );
        logout();
      } else {
        setLoadingProcess(false);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const revertTransaction = async (id, reverse_motivation) => {
    setLoadingProcess(true);
    const data = {
      reverse_motivation,
      moderator_email: email,
    };
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/payments/delivery/reverse/${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            //   "x-authorization": process.env.REACT_APP_ZELLE_BEARER
            'X-Access-Token': token,
          },
          body: JSON.stringify(data),
        }
      );
      const response = await res.json();
      setLoadingProcess(false);
      //   console.log(response)
      return response;
    } catch (e) {
      console.log(e);
      setLoadingProcess(false);
    }
  };

  const revertTransactionYummer = async (trans_data, reverse_motivation) => {
    setLoadingProcess(true);
    const data = {
      auto_incremental_id: trans_data.auto_incremental_id,
      reverse_motivation
    };
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/payments/unprocess/yummer/payment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Token': token,
          },
          body: JSON.stringify(data),
        }
      );
      const response = await res.json();
      setLoadingProcess(false);
      if (res.status >= 400) {
        return { success: false }
      }
      if (response.data) return { success: true }
      return { success: false }
    } catch (e) {
      console.log(e);
      setLoadingProcess(false);
    }
  };

  // funcion para exportar el csv
  // func to export csv
  const onBtnExport = () => {
    gridApiContext.exportDataAsCsv({ allColumns: true });
    toast.success('Descargado CSV, revisa tus descargas', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // funcion que escucha los cambios de la variable rowCount, para cambiar el estado del boton csv
  // rowcount listener to show or not the csv button
  useEffect(() => {
    rowCount === 0 ? setShowBtnCsv(false) : setShowBtnCsv(true);
  }, [rowCount]);

  return (
    <TransContext.Provider
      value={{
        // loaders
        loading,
        setLoading,
        isCorporate,
        setIsCorporate,
        loadingProcess,
        setLoadingProcess,
        loadingSearch,
        setLoadingSearch,
        // to get transactions
        getRejectedTransactions,
        getTransactionsList,
        // for process and search transactions
        // processTransaction,
        processYummer,
        processRides,
        processDingo,
        processDiffered,
        processTransactionHugo,
        seacrhTransaction,
        // for transaction reversal
        revertTransaction,
        revertTransactionYummer,
        apiTypeSource,
        setApiTypeSource,
        // for filters
        maxDate,
        setMaxDate,
        mindate,
        setMinDate,
        gridApiContext,
        setGridApiContext,
        // to csv button
        showBtnCsv,
        setShowBtnCsv,
        rowCount,
        setRowsCount,
        onBtnExport,
        // obtener usuarios por filtro
        getUsersRides,
        // obtener usuarios para dingo
        getUsersDingo,
        // obtener un usuario de delivery
        getUserDelivery,
        // obtener un usuario yummer
        getUsersYummers,
        //user selected
        setSelectedUser,
        email
      }}>
      {children}
    </TransContext.Provider>
  );
};

export default TransState;
