import React, {useContext} from 'react'
import AppContext from '../../context/app/AppContext'
import { GoogleLogin } from "react-google-login";
import '../../Styles/login.css'
import '../../Styles/styles.css'
import Logo from '../../assets/logo.svg'
import { Loader } from '../loader/Loader'


const Login = () => {

    const { signIn, clientGoogleId, loading, setgoogle} = useContext(AppContext)

    // funcion de googlelogin que trae los datos del usuario 
    // googlelogin func to get user info 
    const onSuccess = (res) => {
        // console.log('login data google',res)
        const user = {
            first_name: res.profileObj.givenName,
            last_name: res.profileObj.familyName,
            user_img: res.profileObj.imageUrl,
        }
        signIn(res.tokenId, res.profileObj.name, res.profileObj.email, user)
	}

	// on failure google login 
	const onFailure = (res) => {
		console.log(`something went wrong: ${JSON.stringify(res)}`);
	}

    return(
        <div className="page-container">
        <div className="login-container">
            <img src={Logo} className="login-logo" alt='logo' />
            <p className="login-text">Ingresa con tu correo de Hugo, Yummy o Dingo</p>
            {
                loading ? <div className='loader'><Loader color='var(--primary)' /></div> :
                <GoogleLogin
                    clientId={clientGoogleId}
                    buttonText='Ingresar con Google'
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    className="signInButton"
                />
            }
        </div>
        </div>
    )
}

export default Login