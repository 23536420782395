import React from 'react'
// import {bank as platforms} from './banks'
import {banks as platforms} from './banks'

import './styles.css'

export const Platform = (props) => {

    const renderPlatform = (code) => {
        if(platforms[code]){
            const {assetRoute: bank, name: bankName} = platforms[code]
            return (
                <div className="platform-container">
                    <img className="bank-logo" src={bank} alt="bank icon" />
                    <div className="poppover">
                        <p><span>{code}</span></p>
                        <p>{bankName} <span>{code}</span></p>
                    </div>
                </div>
            )

        }
    }

    const setData = (platform) => {
        switch (platform) {
            case 'ZELLE':
                return <><img className="bank-logo" alt='platform-data' src={platforms.zelle.assetRoute} /> </>
            case 'RESERVE':
                return <><img className="bank-logo" alt='platform-data' src={platforms.reserve.assetRoute} /> </>
            case 'PAGO MOVIL':
                return renderPlatform(props.data.bank)
            case 'Pago Movil':
                return props?.data?.transaction_bank && renderPlatform(props.data.transaction_bank)
            case 'BANCUMBRE':
                return <><img className="bank-logo" alt='platform-data' src={platforms.bancumbre.assetRoute} /> </>
            case 'PIPOL':
                return <><img className="bank-logo" alt='platform-data' src={platforms.pipol.assetRoute} /> </>
            case 'VENMO':
                return <><img className="bank-logo" alt='platform-data' src={platforms.venmo.assetRoute} /> </>
            case 'BINANCE':
                return <><img className="bank-logo" alt='platform-data' src={platforms.binance.assetRoute} /> </>
            case 'CARD':
                return <><img className="bank-logo" alt='platform-data' src={platforms.card.assetRoute} /> </>
            case 'PAYPAL':
                return <><img className="bank-logo" alt='platform-data' src={platforms.paypal.assetRoute} /> </>
            case 'DEPOSIT CASH':
                return <><img className="bank-logo" alt='platform-data' src={platforms.banesco.assetRoute} /> </>
            default:
                return ''
        }
    }

    // console.log('props', props)
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{setData(props.data.platform)}{props.data.platform === "CARD" ? "tarjeta" : props?.data?.platform?.toLowerCase()}</div>
    )
}