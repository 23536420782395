import React, { useState, useContext } from 'react'
import "../../Styles/styles.css";
import TransContext from "../../context/trans/TransContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css'
import { Loader } from '../loader/Loader'
import moment from 'moment'
import CachedIcon from '@material-ui/icons/Cached';
import { sweetalert } from '../../helpers/SweetAlert'

export const SearchEngine = ({
    // propiedades 
    // props 
    getTransactions, // func to get trans, rejected or list, depends of father component 
    setNoCoincidence, // for coincidence status
    setTransactionsFilter, // to set transaction filter variable on father component
    setTransactions, // to set transaction variable on father component
    history, // necesary to know if will get rejected trans o list
    processed,
    setEmptyState,
    vertical,
    source,
}) => {
    const {
        setMaxDate, // func that set max date for datepicker
        setMinDate,// func that set min date for datepicker
        loading,  // loading var for refresh button
        seacrhTransaction,  // function that get filtered transactions
        loadingSearch, // loading for search button
        setLoadingSearch // loading func for search button
    } = useContext(TransContext);

    // days const for datepicker input 
    const days = 1
    // const that store today date 
    const date = new Date();

    // valores formateados para la request de traer las transacciones en un rango de fechas 
    // date values formatted for request to get transactions date range 
    const [valueStart, setValueStart] = useState(moment(new Date(date.getTime() - (days * 24 * 60 * 60 * 1000))).format("D/MM/yyyy"))
    const [valueEnd, setValueEnd] = useState(moment(new Date()).format("D/MM/yyyy"))

    // fechas de inicio para los inputs del buscador 
    // date values for inputs on search engine 
    const [startDate, setStartDate] = useState(new Date(date.getTime() - (days * 24 * 60 * 60 * 1000)));
    const [endDate, setEndDate] = useState(new Date());
    const [isDisabled, setIsDisabled] = useState(true)

    // valores que limitan el rango del datepicker del input de inicio 
    // values that limit the range of datepicker on start input 
    const [startMinDate, setStartMinDate] = useState(new Date(date.getTime() - (60 * 24 * 60 * 60 * 1000)))
    const [startMaxDate, setStartMaxDate] = useState(new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)))

    // limite maximo del datepicker del input de fecha final 
    // max date limit on datepicker end date input
    const [endMaxDate, setEndMaxDate] = useState(new Date(date.getTime()))

    // funcion para refrescar la lista de transacciones 
    // func to refresh transactions list
    const refreshTrans = async () => {
        setTransactionsFilter([])
        setTransactions([])
        const trans = await getTransactions(0, source)
        // seteando el min/max de fechas en el datepicker del filtro de columnas segun la fecha buscada
        // setting the min/max date for filter column datepikcer by search engine range
        setMinDate(new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)))
        setMaxDate(new Date(date.getTime() - (0 * 24 * 60 * 60 * 1000)))
        if (trans) {
            if (trans.length === 0) setEmptyState(true)
            else setEmptyState(false)
            setTransactions(trans)
        }
    }

    // funcion para filtrar las transacciones por fecha haciendo una consulta a la base de datos 
    // func for filter transactions with a DB request 
    const searchFilterFunc = async (e) => {
        e.preventDefault()
        // condicinal que evita que el usuario elija una fecha de inicio superor a la final 
        // conditional that dont let the user choose a "from" date less than "to" date 
        if (startDate > endDate) {
            sweetalert('Alto!', 'No puedes colocar una fecha de inicio superior a la fecha final.', 'error')
        } else {
            // console.log('fechas', valueStart, valueEnd)
            const data = await seacrhTransaction(valueStart, valueEnd, processed, history, vertical, source)
            // console.log(data)
            if (data.length === 0) {
                setNoCoincidence(true)
                setTransactionsFilter([])
            } else {
                setTransactionsFilter(data)
            }
        }
    }

    // funcion que retorna fechas con dias de mas o menos 
    // func for set dates with more or less days
    const setDateFilter = (date, time) => {
        return new Date(new Date(date).getTime() - (time * 24 * 60 * 60 * 1000))
    }

    // funcion que cambia la fecha de inicio 
    // func that change start date
    const onChangeStartDate = (date) => {
        // formatting date for request 
        const dateFormat = moment(date).format("D/MM/yyyy")

        // setting dates to shoy on input and datepicker 
        setStartDate(date)
        setMinDate(setDateFilter(date, 0))
        setValueStart(dateFormat)

        // evaluate if input is empty to disable button, show transactions and change noCoincidence status 
        if (dateFormat.length === 0) {
            setIsDisabled(true)
            setNoCoincidence(false)
            setTransactionsFilter([])
        } else {
            setIsDisabled(false)
            setNoCoincidence(false)
        }
    }

    // funcion que cambia la fecha final 
    // func that change end date
    const onChangeEndDate = (date) => {
        // formatting date for request 
        const dateFormat = moment(date).format("D/MM/yyyy")

        // setting dates to shoy on input and datepicker 
        setStartMinDate(setDateFilter(date, 60))
        setStartMaxDate(setDateFilter(date, 1))
        setMaxDate(setDateFilter(date, 0))
        setEndDate(date)
        setValueEnd(dateFormat)

        // evaluate if input is empty to disable button, show transactions and change noCoincidence status
        if (dateFormat.length === 0) {
            setIsDisabled(true)
            setNoCoincidence(false)
            setTransactionsFilter([])
        } else {
            setIsDisabled(false)
            setNoCoincidence(false)
        }
    }


    return (
        <form onSubmit={(e) => searchFilterFunc(e)}>
            <div className="buscador panel">
                <div className='user-input-container mr-m'>
                    <p className='user-input-label m-0'>Desde</p>
                    <div className='flex'>
                        <DatePicker
                            className="user-input"
                            locale="es"
                            selected={startDate}
                            onChange={(date) => onChangeStartDate(date)}
                            dateFormat="d/MM/yyyy"
                            minDate={startMinDate}
                            maxDate={startMaxDate}
                        />
                    </div>
                </div>
                <div className='user-input-container'>
                    <p className='user-input-label m-0'>Hasta</p>
                    <div className='flex'>
                        <DatePicker
                            className="user-input"
                            locale="es"
                            selected={endDate}
                            onChange={(date) => onChangeEndDate(date)}
                            dateFormat="d/MM/yyyy"
                            maxDate={endMaxDate}
                        />
                    </div>
                </div>
                {
                    loadingSearch ?
                        <div className='loader-box-container ml-l'>
                            <Loader />
                        </div>
                        :
                        <button className="btn2 shadows" type="submit">Buscar</button>
                }
                {
                    !loading &&
                    <CachedIcon
                        style={{ fontSize: 30, margin: '0 0 0 20px', color: 'var(--primary)', cursor: 'pointer' }}
                        onClick={() => refreshTrans()}
                    />
                }
            </div>
        </form>
    )
}

// color: white;
//     /* border: 1px solid red; */
//     width: 200px;
//     height: 35px;
//     /* text-align: center; */
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: aqua;
//     border-radius: 5px;