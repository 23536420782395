import React from 'react'
import AppState from "./context/app/AppState";
import { BrowserRouter } from "react-router-dom";
import Home from './screen/Home';


function App() {
  return (
    <BrowserRouter>
        <AppState>
            <Home/>
        </AppState>
    </BrowserRouter>
  );
}

export default App;
