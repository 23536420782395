import React, { useState, useContext, useEffect } from 'react'
import "../../Styles/styles.css";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import { PermissionsComponent } from '../agGridComponents/PermissionsComponent'
import { IdStatusComponent } from '../agGridComponents/IdStatusComponent'

import { AdminModal } from '../modals/AdminModal'
import { CreateUserModal } from '../modals/CreateUserModal'
import AdminContext from "../../context/admin/AdminContext";
import AppContext from "../../context/app/AppContext";

import PermissionsFilter from '../agGridComponents/PermissionsFilter'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const Admin = () => {

    const { getUsers } = useContext(AdminContext)
    const { userData } = useContext(AppContext)

    const [data, setData] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenUser, setIsOpenUser] = useState(false)
    const [users, setUsers] = useState([])

    useEffect( async ()=>{
        const users = await getUsers()
        // console.log('users', users)
        setUsers(users)
    },[])

    // estado que almacenara la data de la api de ag grid 
    // grid api data state for use ag grid functions 
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    
    // cuando ag grid esta listo, almaneca los datos de la api en el state 
    // when the grid is onGridReady, it store the api on state 
    const onGridReady = async (params) => {
        setGridApi(params.api);
        // setGridApiContext(params.api)
        setGridColumnApi(params.columnApi);
    };

        // funcion que se ejcuta al hacer click en una de las filas para mostrar el modal de la transaccion 
    // on click rows func to show the modal whit transaction data 
    const onSelection = () => {
        let selectedRows = gridApi.getSelectedRows();
        console.log('modal:', selectedRows[0])
        // setData(selectedRows[0])
        setData({...selectedRows[0], permissions: {
            roles_yummy: ['user', 'moderator'],
            roles_wink: ['user']
        }})
        setIsOpen(true)
    }

    // esta funcion evita el rezise de la celda al hacer click sobre ella 
    // this func disable cell rezise on click event 
    const cellClicked = (data) => {
    if (window.getSelection().type !== 'Range')
        {
            //text has not been selected, the cell has been clicked
            // console.log('cellClicked');
        }
    }

    const onFilterChanged = (data) => {
        const rows = gridApi.getDisplayedRowCount()
        // console.log('rows', rows)
        // setRowsCount(rows)
      }

      
    // funcion para manejar el cerrado del modal 
    // to handle close modal 
    const handleClose = () => {
        setIsOpen(false)
        setIsOpenUser(false)
    }

    var permissionsFilterParams = {
        comparator: function (filterLocalDateAtMidnight, cellValue){
        //   console.log('filterLocalDateAtMidnight', filterLocalDateAtMidnight)
        //   console.log('cellValue', cellValue)
        }
      }

    return (
        <>
        <AdminModal 
            isOpen={isOpen} 
            setIsOpen={setIsOpen} 
            data={data} 
            handleClose={handleClose} 
            setUsers={setUsers}
            />
        <CreateUserModal 
            isOpen={isOpenUser} 
            setIsOpen={setIsOpenUser} 
            handleClose={handleClose} 
            setUsers={setUsers}
        />
        <div className="trans-container">
        <p className="btn-yummy btn-rigth" onClick={()=> setIsOpenUser(true)}>+ Agregar usuario</p>
            <div className="trans-table-container panel panel-default ag-theme-alpine">
                <AgGridReact
                defaultColDef={{
                    editable: false,
                    floatingFilter: true, // necesary to show custom components on column filters
                    suppressMenu: true, // supress mini menu on all colunms filters
                    floatingFilterComponentParams: {suppressFilterButton:true}, 
                    rowDeselection: true, // allow deselection on rows
                    filter: true, // show filters on columns
                }}
                overlayNoRowsTemplate={ //this is the message when the data is fetching
                    `<span className="ag-overlay-loading-center loader">Cargando los datos, por favor espera...</span>`
                }
                frameworkComponents={{ //here you can put the custom components, then you cant call they on agGridColumn
                    permissionsComponent: PermissionsComponent, // custom component to show users permissions
                    idStatusComponent: IdStatusComponent, //custom id component to show user status
                    PermissionsFilter: PermissionsFilter
                }}
                gridOptions={{
                    // suppressRowTransform: true,
                    // enableCellTextSelection: true,
                    // ensureDomOrder: true
                }}
                    onFilterChanged={onFilterChanged}
                    rowClass="table-rows pointer"
                    rowSelection={'single'}
                    animateRows={true}
                    onGridReady={onGridReady}
                    onRowClicked={onSelection}
                    onCellClicked={cellClicked}
                    headerHeight={40}
                    rowHeight={40}
                    rowData={users}>
                    <AgGridColumn 
                        cellClass="cell-id cell-center overflow" 
                        headerName="ID" 
                        field="auto_incremental_id" 
                        width={100} 
                        // cellRenderer="idStatusComponent"
                        filter="agTextColumnFilter" 
                        sortable={true} 
                        unSortIcon={true} 
                          ></AgGridColumn>
                    <AgGridColumn 
                        cellClass="hidden cell-center" 
                        headerName="Nombre" 
                        resizable={true}
                        field="full_name" 
                        minWidth={180} 
                        flex={1} 
                        filter="agTextColumnFilter" 
                          ></AgGridColumn>
                    <AgGridColumn 
                        cellClass="hidden cell-center" 
                        headerName="Correo" 
                        resizable={true}
                        field="email" 
                        flex={1} 
                        minWidth={180} 
                        filter="agTextColumnFilter" 
                          ></AgGridColumn>
                    {
                        userData && userData.roles.includes('administrator') &&
                        <AgGridColumn 
                            cellClass="hidden cell-center overflow" 
                            headerName="Permisos Yummy" 
                            field="roles" 
                            width={180} 
                            cellRenderer="permissionsComponent"
                            filter="agTextColumnFilter" 
                            floatingFilterComponent="PermissionsFilter" 
                            floatingFilterComponentParams={{suppressFilterButton: true }}
                            ></AgGridColumn>
                    }
                    {
                        userData && userData.roles_wink.includes('administrator') &&
                        <AgGridColumn 
                            cellClass="hidden cell-center overflow" 
                            headerName="Permisos Wink" 
                            field="roles_wink" 
                            width={180} 
                            cellRenderer="permissionsComponent"
                            filter="agTextColumnFilter" 
                            floatingFilterComponent="PermissionsFilter" 
                            floatingFilterComponentParams={{suppressFilterButton: true }}
                            ></AgGridColumn>
                    }
                </AgGridReact>
            </div>
        </div>
        </>
    )
}

export default Admin