import React, { useState } from 'react'
import Switch from "react-switch";

import Pm from '../../assets/methods/pm.svg'
import Reserve from '../../assets/methods/reserve.svg'
import Card from '../../assets/methods/card.svg'
import Visible from '../../assets/methods/eye-open.svg'
import NotVisible from '../../assets/methods/eye-closed.svg'

const MethodCard = ({ data }) => {

    const [checked, setCheckd] = useState(false)
    const [selected, setSelected] = useState('Credits')
    const services = data.services
    const hasAccount = services[0].account_data

    console.log(data)
    const images = {
        "Pago movil": Pm,
        "Reserve": Reserve,
        "Tarjeta": Card,
    }

    const selectedService = (e) => {
        console.log(e.target.value)
        setSelected(e.target.value)
    }

    const renderAccount = (serviceName) => {
        const selectedService = services.find(el => el.name === serviceName)
        const data = selectedService.account_data
        console.log("🚀 ~ file: MethodCard.jsx ~ line 29 ~ renderAccount ~ data", data)
        if(data && data.data){
            return(
                <>
                    <p className='text-gray text-size-large m-0'>Cuenta</p>
                    <input type='text' className='method-card-input' value={data.account_name} />
                    <p className='text-gray text-size-large m-0'>Datos</p>
                    <input type='text' className='method-card-input' value={data.data.data1} />
                    <textarea name="paragraph_text" className='method-card-textarea' cols="2" rows="4">{data.data.data2}</textarea>
                    {/* <input type='text' className='method-card-input' value={data.data.data2} /> */}
                </>
            )
        }
        else if(data && !data.data){
            return(
                <>
                    <p className='text-gray text-size-large m-0'>Cuenta</p>
                    <input type='text' className='method-card-input' value={data.account_name} />
                </>
            )
        }
        else {
            console.log('nada')
        }
    }

    const renderSwitch = (checked, func) => {
        return (
            <Switch 
            onColor="#DEDEDE"
            checked={checked}
            onChange={() => func(!checked)}
            onHandleColor="#00c389"
            offHandleColor="#c4c4c4"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor="#DEDEDE"
            // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={10}
            width={25}
            className="react-switch"
            id="material-switch"
          />
        )
    }

    const handleSwitch = (service) => {

    }


    return (
        <div className={hasAccount ? 'method-card-container shadows' : 'method-card-container shadows no-account-card'}>
            <div className={hasAccount ? 'method-card-box' : 'method-card-box no-account-box' }>
                <img src={images[data.name]} alt="imagen de metodo de pago" />
                <p>{data.name}</p>
                <div className='method-card-services-container'>
                    <table className='methods-table' cellpadding="0" border="0" cellspacing="0">
                        <thead>
                            <th>Servicio</th>
                            <th style={{ textAlign: 'right' }}>Status</th>
                            <th></th>
                            <th></th>
                        </thead>
                        <tbody>
                            <td>
                                {
                                    services.map(service => {
                                        return (
                                            <tr style={{ width: '80px' }}>{service.name}</tr>
                                        )
                                    })
                                }
                            </td>
                            <td>
                                {
                                    services.map(service => {
                                        return (
                                            <tr style={{ width: '110px' }}>{service.enabled ? 'Habilitado' : 'Desabilitado'}</tr>
                                        )
                                    })
                                }
                            </td>
                            <td>
                                {
                                    services.map(service => {
                                        return (
                                            <tr>{renderSwitch(service.enabled, handleSwitch(service))}</tr>
                                        )
                                    })
                                }
                            </td>
                            <td>
                                {
                                    services.map(service => {
                                        if(!service.enabled){
                                            return (
                                                <tr>{service.visible ? <img className='eye' src={Visible} alt="Visible" /> : <img className='eye' src={NotVisible} alt="Not Visible" />}</tr>
                                            )
                                        }
                                        return <tr style={{ color: 'transparent' }}>a</tr>
                                    })
                                }
                            </td>
                        </tbody>
                    </table>
                </div>
            </div>
            {
                hasAccount &&
                <div className='method-card-box accounts-box'>
                    <select className='method-card-service-selector' onChange={(e) => selectedService(e)}>
                        {
                            services.map(service => (
                                <option>{service.name}</option>
                            ))
                        }
                    </select>
                    <div className='method-card-accounts-container'>
                        {
                            selected &&
                            renderAccount(selected)
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default MethodCard