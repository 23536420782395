import React, { useState, useEffect, useContext } from 'react' 
import { Loader } from '../loader/Loader'
import adminContext from "../../context/admin/AdminContext";
import moment from 'moment';

export default function SearchWithdrawals({
    setData,
    setNoCoincidence,
    setVerticalFunc
}) {

    const {
        loading,
        getWithdrawalsUserList
    } = useContext(adminContext)

    const [phone, setPhone] = useState('')
    const [vertical, setVertical] = useState('delivery')
    const [disabled, setDisabled] = useState(true)

    useEffect(()=>{
        (phone && vertical) ? setDisabled(false) : setDisabled(true)
    },[phone, vertical])

    const handleChangePhone = (event) => {
        const phone = event.target.value
        setPhone(phone)
    }

    const hanldeChangeVertical = (event) => {
        const vertical = event.target.value
        console.log("🚀 ~ file: SearchWithdrawals.jsx ~ line 32 ~ hanldeChangeVertical ~ vertical", vertical)
        setVertical(vertical)
        setVerticalFunc(vertical)
    }

    const searchFilterFunc = async (event) => {
        event.preventDefault()
        if(!phone && !vertical) {
            return
        }
        const data = {
            phone: `+${phone}`,
            vertical
        }
        let res = await getWithdrawalsUserList(data)
        console.log("🚀 ~ file: SearchWithdrawals.jsx ~ line 26 ~ searchFilterFunc ~ data", res)
        if(res.withdrawals.length === 0){
            setNoCoincidence(true)
            setData([])
            return
        }
        const response = res.withdrawals.map(el => {
            return {
                ...el,
                date: moment(el.created_at.$date).format("D/MM/yyyy")
            }
        })
        setNoCoincidence(false)
        setData(response)
    }


    return (
        <form onSubmit={(e)=> searchFilterFunc(e)}>
            <div className="buscador panel">
                <div className='user-input-container mr-m'>
                    <p className='user-input-label m-0'>Número de teléfono</p>
                    <div className='flex'>
                        {/* <p style={{fontSize: 14, margin: 0}}>+58</p> */}
                        <input 
                            type='number'
                            className="user-input"
                            placeholder='584128884455'
                            style={{ paddingLeft: 2 }}
                            value={phone}
                            onChange={(e)=> handleChangePhone(e)}
                        />
                    </div>
                </div>
                <div className='user-input-container'>
                    <p className='user-input-label m-0'>Vertical</p>
                    <div className='flex'>
                        <select className="user-input" defaultValue="delivery" onChange={(e) => hanldeChangeVertical(e)} >
                            <option value="delivery">Yummy Delivery</option>
                            <option value="rides">Yummy Rides</option>
                            <option value="all">Todas</option>
                        </select>
                    </div>
                </div>
                {
                    !loading ?
                    <button 
                    className={
                        !disabled ? "btn2 shadows"
                        : "btn2 shadows btn-disabled"
                        } 
                    disabled={disabled} 
                    type="submit">Buscar</button>
                    :
                    <div className='loader-box-container ml-l'>
                        <Loader />
                    </div>
                }
            </div> 
        </form>
        )
}


// amount: 1
// amount_bsd: 6.28
// auto_incremental_id: 100
// bank_error: false
// created_at: {$date: 1661369994838}
// debit_in_app: true
// debit_in_bank: true
// original_payment_id: "000000534760"
// platform: "Pago Movil"
// refund_in_app: false
// transaction_bank: "0102"
// updated_at: {$date: 1661370005085}
// usd_rate: 6.28
// user_phone: "+584129407073"
// user_transaction_id: "V24669313"
// // user_transaction_phone: "+584128883349"
// user_vertical_id: "6127f50f3ceab1babbf63547"
// vertical: "rides"