import React, { useState, useContext ,useEffect} from 'react';
import { Modal, Paper } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Radio, RadioGroup, FormControlLabel, FormControl, Checkbox  } from "@material-ui/core";
import './modal.css'
import AdminContext from "../../context/admin/AdminContext";
import { Loader } from '../loader/Loader'
import { sweetalert } from '../../helpers/SweetAlert';

import Edit from '../../assets/permissions/edit.svg'
import Revert from '../../assets/permissions/revert.svg'
import Admin from '../../assets/permissions/admin.svg'

export const CreateUserModal = ({ 
  isOpen, 
  setIsOpen, 
  handleClose, 
  setUsers
} ) => {


    const {
        loading,
        createUser,
        getUsers
    } = useContext(AdminContext)
    // input data
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    // checkboxe data 
    const [roles, setRoles] = useState({
        user: false,
        moderator: false,
        administrator: false
    })
    const [ hasTruthy, setHasTruthy ] = useState(false)

    // func to set the value of radio button
    const handleSetRoles = (rolName, state) => {
        setRoles(prevState => {
            return {
                ...prevState,
                [rolName]: state
            }
        });
    };

    useEffect(() => {
        const someTruthy = Object.values(roles).some(val => val === true);
        console.log(someTruthy);
        setHasTruthy(someTruthy)
    }, [roles])
    

    // close modal func, initialize data
    const closeModal = () => {
      setName('')
      setEmail('')
      setRoles({
        user: false,
        moderator: false,
        administrator: false
      })
      handleClose()
    }

    const requestCreateUser = async () => {
        let rolesData = []

        for (let rol in roles){
            if(roles[rol]){
                rolesData.push(rol)
            }
        }

        // console.log('rolesData', rolesData)
        if(name.length > 0 && (email.includes("@yummysuperapp.com") || email.includes("@hugoapp.com") || email.includes("@somosdingo.com")) > 0){
            const response = await createUser(name, email, rolesData)
            closeModal()
            sweetalert('Listo', response.message, 'success')
            setUsers([])
            const users = await getUsers()
            setUsers(users)
            // console.log(response)
        }
    }

    return (
        
          <Modal 
            className="modal" 
            open={isOpen}
            onClose={closeModal}
          >
            <Paper className="paper">
            <HighlightOffIcon className="close-btn" onClick={()=> closeModal()} />
              <div className="selectionContainer no-border" style={{width: "80%"}}>
                <p className="input-label" style={{margin: "20px auto 5px 10px"}}>Nombre completo</p>
                <input 
                      className="input-value"
                      style={{ width: '95%' }}
                      id="search-engine" 
                      type="text"
                      value={name}
                      onChange={(e)=> setName(e.target.value)}
                  />
                <p className="input-label" style={{margin: "20px auto 5px 10px"}}>Email</p>
                <input 
                      className="input-value"
                      style={{ width: '95%' }}
                      type="email"
                      value={email}
                      onChange={(e)=> setEmail(e.target.value)}
                  />
              </div>
            <p className="input-label text-center">Roles</p>
                    <div className="checkbox-info-container">
                        <div style={{display: 'flex'}}>
                            <img src={Edit} className="icon-create" alt="edit icon" />
                            <div>
                                <p className="title-modal permi-title">Usuario</p>
                                <p className="radio-info">Permite al usuario procesar transacciones</p>
                            </div>
                        </div>
                        <Checkbox  
                            value="user" 
                            onChange={()=> handleSetRoles( "user", !roles.user)}
                            style ={{
                                color: "var(--primary)",
                              }}
                        />
                    </div>
                    <div className="checkbox-info-container">
                        <div style={{display: 'flex'}}>
                            <img src={Revert} className="icon-create" alt="revert icon" />
                            <div>
                                <p className="title-modal permi-title">Moderador</p>
                                <p className="radio-info">Permite al usuario revertir transacciones procesadas</p>
                            </div>
                        </div>
                        <Checkbox  
                            value="moderator"
                            onChange={()=> handleSetRoles("moderator", !roles.moderator)}    
                            style ={{
                                color: "var(--primary)",
                              }}
                        />
                    </div>
                    <div className="checkbox-info-container">
                        <div style={{display: 'flex'}}>
                            <img src={Admin} className="icon-create" alt='create icon'/>
                            <div>
                                <p className="title-modal permi-title">Administrador</p>
                                <p className="radio-info">Permite al usuario administrar los permisos y status de los usuarios</p>
                            </div>
                        </div>
                        <Checkbox  
                            value="administrator"
                            onChange={()=> handleSetRoles("administrator", !roles.administrator)}      
                            style ={{
                                color: "var(--primary)",
                              }}
                        />
                    </div>
                {
                    loading ? 
                    <Loader color='#fff' /> : 
                    <button 
                        type="submit" onClick={() => requestCreateUser()} 
                        disabled={name.length > 0 && email.length > 0 && hasTruthy && (email.includes("@yummysuperapp.com") || email.includes("@hugoapp.com") || email.includes("@somosdingo.com")) ? false : true} 
                        className={name.length > 0 && hasTruthy && (email.includes("@yummysuperapp.com") || email.includes("@hugoapp.com") || email.includes("@somosdingo.com")) > 0 ? "btn-process btn-bg" : "btn-process btn-bg-disabled"}
                    >CREAR USUARIO</button>
                }
            </Paper>
          </Modal>
    )
}

