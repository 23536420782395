import React from "react";
import AdminState from '../context/admin/AdminState'
import Admin from '../components/adminPermisions/adminPermisions'
import Menu from '../components/menu/Menu'

const RejectedTransactions = () => {
  return (
    <>
      <AdminState>
          <Menu />
          <Admin />
      </AdminState>
    </>
  );
};

export default RejectedTransactions;
