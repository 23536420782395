import React, { useState, useContext, useEffect} from 'react';
import { Modal, Paper  } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Switch from "react-switch";
import './modal.css'
import AdminContext from "../../context/admin/AdminContext";
import AppContext from "../../context/app/AppContext";
import { Loader } from '../loader/Loader'
import { sweetalert, sweetalertRequest } from '../../helpers/SweetAlert';

import Read from '../../assets/permissions/read.svg'
import Edit from '../../assets/permissions/edit.svg'
import EditIcon from '../../assets/edit.svg'
import Revert from '../../assets/permissions/revert.svg'
import Admin from '../../assets/permissions/admin.svg'
import Trash from '../../assets/trash.svg'

export const AdminModal = ({ 
  isOpen, 
  setIsOpen, 
  data, 
  handleClose, 
  setUsers
} ) => {

    const [type, setType] = useState([]);
    const [value, setValue] = useState('')

    const [checkedRead, handleChangeRead] = useState('')
    const [checkedWrite, handleChangeWrite] = useState('')
    const [checkedRevert, handleChangeRevert] = useState('')
    const [checkedAdmin, handleChangeAdmin] = useState('')
    const [reOpen, setReOpen] = useState(false)
    const [editName, setEditName] = useState(false)
    const [editEmail, setEditEmail] = useState(false)
    const [name, setName] = useState(data.full_name)
    const [email, setEmail] = useState(data.email)
    const [oldName, setOldName] = useState(data.full_name)
    const [oldEmail, setOldEmail] = useState(data.email)
// console.log(data)
    
    const { 
        loading, // loader
        UpdateUSerRoles, // update roles func 
        updateUserInfo, // update user data
        deleteUserData, // delete user func
        getUsers // get all users info
    } = useContext(AdminContext);

    const { userData } = useContext(AppContext)

    useEffect(()=>{
        if(isOpen){
            setName(data.full_name)
            setEmail(data.email)
            setOldName(data.full_name)
            setOldEmail(data.email)
            const roles = value === "Yummy" 
                ? data.roles 
                : value === "Wink" 
                ? data.roles_wink 
                : ''

            if(roles && value){
                // roles.includes('visitor') ? handleChangeRead(true) : handleChangeRead(false)
                roles.includes('user') ? handleChangeWrite(true) : handleChangeWrite(false)
                roles.includes('moderator') ? handleChangeRevert(true) : handleChangeRevert(false)
                roles.includes('administrator') ? handleChangeAdmin(true) : handleChangeAdmin(false)
            }
        }
    },[data, isOpen, value])

    useEffect(()=>{
        if(
            userData.roles.length > 0 && 
            userData.roles_wink.length === 0
        ){
            setType(["Yummy"])
        }
        if(
            userData.roles.length === 0 && 
            userData.roles_wink.length > 0
        ){
            setType(["Wink"])
        }
        if(
            userData.roles.length > 0 && 
            userData.roles_wink.length > 0
        ){
            setType(["Yummy", "Wink"])
        }
    },[userData])

    // close modal func, initialize data
    const closeModal = () => {
      setName('')
      setEmail('')
      setEditName(false)
      setEditEmail(false)
      handleClose()
      handleChangeRead(false)
      handleChangeWrite(false)
      handleChangeRevert(false)
      handleChangeAdmin(false)
    }

    const cleanList = async () => {
        setUsers([])
        const users = await getUsers()
        setUsers(users)
    }

    const renderData = (key, value) => {
      return (
        <div className="data-container">
          <p className="key">{key}:</p>
          <p className="value">{value}</p>
        </div>
      )
    }

    const renderSwitch = (checked, func) => {
        return (
            <Switch 
            onColor="#DEDEDE"
            checked={checked}
            onChange={() => func(!checked)}
            onHandleColor="#5ec568"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor="#bfbfbf"
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={38}
            className="react-switch"
            id="material-switch"
          />
        )
    }

    const renderPermissions = () => {
        return (
            <>
                {/* <div className="permission-container">
                    <div className="permission-icon-container">
                        <img src={Read} className="permission-icon" />
                    </div>
                    <div className="permission-info">
                        <p className="title-modal permi-title">Visitante</p>
                        <p className="key" style={{fontSize: 12, marginLeft: 10}}>Permite al usuario vizualizar y hacer busquedas de las transacciones</p>
                    </div>
                </div> */}
                <div className="permission-container">
                    <div className="permission-icon-container">
                        <img src={Edit} className="permission-icon" />
                    </div>
                    <div className="permission-info">
                        <p className="title-modal permi-title">Usuario</p>
                        <p className="key" style={{fontSize: 12, marginLeft: 10}}>Permite al usuario procesar transacciones</p>
                    </div>
                    <div className="permission-toggle">
                        {renderSwitch(checkedWrite, handleChangeWrite)}
                    </div>
                </div>
                <div className="permission-container">
                    <div className="permission-icon-container">
                        <img src={Revert} className="permission-icon" />
                    </div>
                    <div className="permission-info">
                        <p className="title-modal permi-title">Moderador</p>
                        <p className="key" style={{fontSize: 12, marginLeft: 10}}>Permite al usuario revertir transacciones procesadas</p>
                    </div>
                    <div className="permission-toggle">
                        {renderSwitch(checkedRevert, handleChangeRevert)}
                    </div>
                </div>
                <div className="permission-container">
                    <div className="permission-icon-container">
                        <img src={Admin} className="permission-icon" />
                    </div>
                    <div className="permission-info">
                        <p className="title-modal permi-title">Administrador</p>
                        <p className="key" style={{fontSize: 12, marginLeft: 10}}>Permite al usuario administrar los permisos y status de los usuarios, ademas de poseer los privilegos de los dos permisos anteriores</p>
                    </div>
                    <div className="permission-toggle">
                        {renderSwitch(checkedAdmin, handleChangeAdmin)}
                    </div>
                </div>
            </>
        )
    }

    const updateRoles = async (value) => {
        let added = []
        let removed = []
        const roles = {
            user: checkedWrite ? 'user' : null,
            moderator: checkedRevert ? 'moderator' : null,
            administrator: checkedAdmin ? 'administrator' : null,
        }
        for (let rol in roles){
            if(roles[rol] !== null){
                added.push(roles[rol])
            }else{
                removed.push(rol)
            }
          }
          if(oldName !== name || oldEmail !== email){
              const response = await UpdateUSerRoles(data.id, added, removed)
              const responseUserInfo = await requestUpdateUserInfo()
              if(responseUserInfo.success ){
                  sweetalert("Listo!", "Todos los datos fueron actualizados", "success")
                  closeModal()
                  cleanList()
                }else {
                    sweetalert("Error!", response.message, "error")
                    closeModal()
                }
                
        }else {
            const response = await UpdateUSerRoles(data.id, added, removed, value)
            if(response.success ){
                sweetalert("Listo!", response.message, "success")
                closeModal()
                cleanList()
                }else {
                sweetalert("Error!", response.message, "error")
                closeModal()
            }

        }
    }

    const requestDeleteUser = async () => {
        closeModal()
        const alertRes = await sweetalertRequest(
            "Seguro?", 
            `Estas apunto de eliminar al usuario ${data.email}`, 
            "warning",
            deleteUserData,
            data.id)
        if(alertRes){
            // console.log(alertRes.value)
            if(alertRes.value.success){
                sweetalert("Listo!", alertRes.value.message, "success")
                cleanList()
              }else {
                sweetalert("Error!", alertRes.value.message, "error")
            }
        }
    }

    const requestUpdateUserInfo = async () => {
        const userData = {
            full_name: name,
            email
        }
        const res = await updateUserInfo(userData, data.id)
        return res
    }

      // func to set the value of radio button
  const handleChange = (event) => {
    setValue(event.target.value);
  };

    return (
        
          <Modal 
            className="modal" 
            open={isOpen}
            onClose={closeModal}
          >
            <Paper className="paper">
            <HighlightOffIcon className="close-btn" onClick={()=> closeModal()} />
            {
              data && 
              <div className="info-container">
                {/* <div className="data-container">
                    <p className="key">Status:</p>
                    <select className="permission-modal-select">
                        <option value="able">Habilitado</option>
                        <option value="unable">Deshabilitado</option>
                    </select>
                </div> */}
                {/* {
                  dataTransaction.map(data => {
                    return renderData(data.key, data.value)
                  })
                } */}
                <div className="data-container">
                    <p className="key">ID:</p>
                    <p className="value">{data.auto_incremental_id}</p>
                </div>
                <div className="data-container">
                    <img src={EditIcon} className="edit-icon" onClick={() => setEditName(!editName)} />
                    <p className="key">Nombre:</p>
                    {
                        editName ? 
                        <input 
                            className="edit-user-data"
                            value={name}
                            onChange={(e)=> setName(e.target.value)}
                        />
                        :
                        <p className="value">{name}</p>
                    }
                </div>
                <div className="data-container">
                    <img src={EditIcon} className="edit-icon" onClick={() => setEditEmail(!editEmail)} />
                    <p className="key">Correo:</p>
                    {
                        editEmail ?
                        <input
                            className="edit-user-data"
                            value={email}
                            onChange={(e)=> setEmail(e.target.value)}
                        /> 
                        :
                        <p className="value">{email}</p>
                    }
                </div>
              </div>
            }
                <p className="title-modal">Permisos</p>
                <div className="type-container">
                    {
                        type.map((type, i) => {
                            return (
                                <label className="selected-container" key={i}>
                                <input
                                    type="radio"
                                    className="input-radio"
                                    name="type"
                                    value={type}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span className="checkmark">{type}</span>
                                </label>
                            )
                        })
                    }
                </div>
                <div className="permissions-modal-container">
                    {value === "Yummy" && renderPermissions()}
                    {value === "Wink" && renderPermissions()}
                </div>
                {
                    value &&
                    <>
                        {
                            loading ? 
                            <Loader color='#fff' /> : 
                            <button type="submit" onClick={() => updateRoles(value)} className="btn-process btn-bg" >GUARDAR</button>
                        }
                        {
                            !loading &&
                            <button type="submit" onClick={() => requestDeleteUser()} className="btn-secondary" ><img src={Trash} />ELIMINAR USUARIO</button>
                        }
                    </>
                }
            </Paper>
          </Modal>
    )
}

