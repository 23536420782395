import React, { useState, useContext, useEffect } from 'react'
import "../../Styles/styles.css";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import { Platform } from "../agGridComponents/Platform";
import { IdStatusComponent } from '../agGridComponents/IdStatusComponent'
import { StatusComponent } from '../agGridComponents/StatusComponent'
import { IdComponent } from "../agGridComponents/IdComponent";
import CustomDateComponent from '../agGridComponents/CustomDateComponent'
import StatusFilter from '../agGridComponents/StatusFilter';
import SearchWithdrawals from './SearchWithdrawals';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const Admin = () => {

    const [data, setData] = useState([])
    const [noCoincidence, setNoCoincidence] = useState(false)
    const [vertical, setVertical] = useState('delivery')


    // estado que almacenara la data de la api de ag grid 
    // grid api data state for use ag grid functions 
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    
    // cuando ag grid esta listo, almaneca los datos de la api en el state 
    // when the grid is onGridReady, it store the api on state 
    const onGridReady = async (params) => {
        setGridApi(params.api);
        // setGridApiContext(params.api)
        setGridColumnApi(params.columnApi);
    };

    // parametros de filtro para la columna de fechas 
    // filter params for date column filter 
    var filterParams = {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = cellValue;
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split('/');
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true,
        minValidYear: 2000
      };


    // esta funcion evita el rezise de la celda al hacer click sobre ella 
    // this func disable cell rezise on click event 
    const cellClicked = (data) => {
    if (window.getSelection().type !== 'Range')
        {
            //text has not been selected, the cell has been clicked
            // console.log('cellClicked');
        }
    }

    return (
        <>
        <div className="trans-container">
        <div className="trans-buscador-container">
            <SearchWithdrawals 
                setData={setData}
                setNoCoincidence={setNoCoincidence}
                setVerticalFunc={setVertical}
            />
        </div>
            {
                noCoincidence && <p className="no-coincidence m-0-auto">No se encontraron transacciones.</p>
            }
            {
                data.length > 0 && 
                <div style={{ width: '85%', margin: '0 auto' }}>
                    <p className='text-size-small bold m-0'>Vertical</p>
                    <p className='text-size-large bold text-gray m-0'>
                        {vertical === 'rides' && 'Yummy Rides'}
                        {vertical === 'delivery' && 'Yummy Delivery'}
                        {vertical === 'all' && 'Todas'}
                    </p>
                    <p className="text-size-small bold m-1">Leyenda</p>
                    <p className="text-size-small bold text-gray m-0">DB = Debitado en banco</p>
                    <p className="text-size-small bold text-gray m-0">DA = Debitado en app</p>
                    <p className="text-size-small bold text-gray m-0">RE = Reembolsado</p>
                </div>
            }
            <div className="trans-table-container panel panel-default ag-theme-alpine">
                    {
                        data.length > 0 &&
                        <AgGridReact
                        defaultColDef={{
                            editable: false,
                            floatingFilter: true, // necesary to show custom components on column filters
                            suppressMenu: true, // supress mini menu on all colunms filters
                            floatingFilterComponentParams: {suppressFilterButton:true}, 
                            rowDeselection: true, // allow deselection on rows
                            filter: true, // show filters on columns
                        }}
                        overlayNoRowsTemplate={ //this is the message when the data is fetching
                            `<span className="ag-overlay-loading-center loader">Cargando los datos, por favor espera...</span>`
                        }
                        frameworkComponents={{ //here you can put the custom components, then you cant call they on agGridColumn
                            Platform: Platform, // custom component to show users permissions
                            idStatusComponent: IdStatusComponent,
                            agDateInput: CustomDateComponent,
                            IdComponent: IdComponent, //custom id component to show user status
                            StatusComponent: StatusComponent, //custom id component to show user status
                            statusFilter: StatusFilter, //custom id component to show user status
                        }}
                            rowClass="table-rows pointer"
                            rowSelection={'single'}
                            animateRows={true}
                            onGridReady={onGridReady}
                            onCellClicked={cellClicked}
                            headerHeight={40}
                            rowHeight={40}
                            rowData={data}>
                            <AgGridColumn 
                                cellClass="cell-id cell-center overflow" 
                                headerName="ID" 
                                field="auto_incremental_id" 
                                width={100} 
                                cellRenderer="IdComponent"
                                filter="agTextColumnFilter" 
                                sortable={true} 
                                unSortIcon={true} 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Teléfono de usuario" 
                                resizable={true}
                                field="user_phone" 
                                minWidth={180} 
                                flex={1} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Teléfono del Pago móvil" 
                                resizable={true}
                                field="user_transaction_phone" 
                                minWidth={200} 
                                flex={1} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Cédula del Pago móvil" 
                                resizable={true}
                                field="user_transaction_id" 
                                minWidth={200} 
                                flex={1} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="DB" 
                                resizable={true}
                                field="debit_in_bank" 
                                // flex={1} 
                                cellRenderer="StatusComponent"
                                width={100} 
                                filter="agTextColumnFilter" 
                                  
                                floatingFilterComponent="statusFilter" 
                                floatingFilterComponentParams={{suppressFilterButton: true }}></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="DA" 
                                resizable={true}
                                field="debit_in_bank" 
                                // flex={1} 
                                cellRenderer="StatusComponent"
                                width={100} 
                                filter="agTextColumnFilter" 
                                  
                                floatingFilterComponent="statusFilter" 
                                floatingFilterComponentParams={{suppressFilterButton: true }}></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="RE"
                                field="refund_in_app" 
                                cellRenderer="StatusComponent"
                                // flex={1} 
                                width={100}
                                resizable={true} 
                                filter="agTextColumnFilter" 
                                  
                                floatingFilterComponent="statusFilter" 
                                floatingFilterComponentParams={{suppressFilterButton: true }}></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Referencia" 
                                resizable={true}
                                field="original_payment_id" 
                                // flex={1} 
                                minWidth={120} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Monto $" 
                                resizable={true}
                                field="amount" 
                                // flex={1} 
                                width={120} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Monto Bs." 
                                resizable={true}
                                field="amount_bsd" 
                                // flex={1} 
                                width={120} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center" 
                                headerName="Tasa USD" 
                                resizable={true}
                                field="usd_rate" 
                                // flex={1} 
                                width={120} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Vertical" 
                                resizable={true}
                                field="vertical" 
                                // flex={1} 
                                width={120} 
                                filter="agTextColumnFilter" 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center cell-x-center" 
                                headerName="Fecha" 
                                field="date" 
                                width={200} 
                                filter="agDateColumnFilter" 
                                sortable={true} 
                                unSortIcon={true} 
                                filterParams={filterParams} 
                                  ></AgGridColumn>
                            <AgGridColumn 
                                cellClass="hidden cell-center overflow cell-x-center" 
                                headerName="Tipo" 
                                field="platform" 
                                cellRenderer="Platform" 
                                width={180} 
                                // filter="agTextColumnFilter" 
                                floatingFilterComponent="platformFilter" 
                                floatingFilterComponentParams={{suppressFilterButton: true }}></AgGridColumn>
                        </AgGridReact>
                    }
            </div>
        </div>
        </>
    )
}

export default Admin