import React, { useState, useContext } from 'react';
import './modal.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '../../assets/search.svg';
import { Loader } from '../loader/Loader';
import TransContext from '../../context/trans/TransContext';

export const Dingo = ({ setData, setExtraData }) => {
  // get users for dingo
  const { getUsersDingo, setSelectedUser, loadingProcess } =
    useContext(TransContext);
  const [inputData, setInputData] = useState('');
  // selected user
  const [user, setUser] = useState('');
  const [usersData, setUsersData] = useState([]);
  const [selectOption, setSelectUser] = useState('phone');
  const [results, setResults] = useState(false);

  // to set input data
  const onChange = (e) => {
    // console.log(e.target.value)
    setInputData(e.target.value);
  };

  // onSelect user
  const selectUser = (user) => {
    setUser(user);
    setSelectedUser(user);
    setData(user.phone);
    setExtraData(user);
  };

  // onFilter users
  const filter_users = async () => {
	const data = isNaN(inputData) ? inputData : `+${inputData.toString()}`
    const res = await getUsersDingo(selectOption, data);
    if (res && res.length > 0) {
      // console.log(res.data)
      setUsersData(res);
      setResults(false);
    } else {
      setResults(true);
    }
  };

  // onClean user selected
  const cleanUSer = () => {
    setUser('');
    setData('');
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        {user ? (
          <div className="user-selected-conatiner">
            <HighlightOffIcon
              className="close-btn"
              style={{ position: 'absolute', right: 20 }}
              onClick={() => cleanUSer()}
            />
            <p>
              Nombre: <span className="user-span">{user.name}</span>
            </p>
            <p>
              Telefono: <span className="user-span">{user.phone}</span>
            </p>
          </div>
        ) : (
          <>
            <p className="input-label">Buscar usuario</p>
            <div className="search-user-container">
              <select
                className="select-user"
                onChange={(e) => setSelectUser(e.target.value)}>
                <option value="phone">Telefono</option>
                <option value="name">Nombre</option>
              </select>
              <input
                className="input-user"
                id="search-engine"
                type={ selectOption === 'phone' ? "number" : "text"}
                placeholder={
                  selectOption === 'phone' ? ' Ej: 584141234567' : ' Juan Perez'
                }
                value={inputData}
                onChange={(e) => onChange(e)}
              />
              <div className="search-user" onClick={filter_users}>
                {loadingProcess ? (
                  <Loader color="#fff" />
                ) : (
                  <img src={SearchIcon} className="search-icon" />
                )}
              </div>
            </div>
            <div className="table-container">
              <table className="users-table">
                <thead className="users-head">
                  <tr>
                    <th>Nombre</th>
                    <th>Telefono</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.map((user) => {
                    return (
                      <tr className="user-tr" onClick={() => selectUser(user)}>
                        <td className="user-td">{user.name}</td>
                        <td className="user-td">{user.phone}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {results && (
              <p className="no-coincidence">
                No se encontraron coincidencias...
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
};
