import React from 'react'
import './styles.css'

export const HoverComponent = ({classType, img, type, active}) => {
    return (
        <div className={classType}>
            <img src={img}/>
            <div className="permission-poppover-info">
                <p className="permission-poppover-title">{type}</p>
                <p className="permission-poppover-description">{active ? "Habilitado" : "Desabilitado"}</p>
            </div>
        </div>
    )
}