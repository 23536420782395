import React, { useState, useContext } from "react";
import "../../Styles/styles.css";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Platform } from "../agGridComponents/Platform";
import CustomDateComponent from '../agGridComponents/CustomDateComponent'
import PlatformFilter from '../platformFilter/PlatformFilter'
import { IdComponent } from "../agGridComponents/IdComponent";
import TransContext from '../../context/trans/TransContext'
import AppContext from '../../context/app/AppContext'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export const TransactionTable = ({ isRejected, trans, setIsOpen, setIsOpenRevert, setTransData, runToast }) => {

  // func from context to store gridApi and use it for export csv file
  const {
    setGridApiContext,
    setRowsCount
  } = useContext(TransContext);

  const {
    userData,
  } = useContext(AppContext);
  const roles = userData.roles

  // estado que almacenara la data de la api de ag grid 
  // grid api data state for use ag grid functions 
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);


  // for make posible the double click for copy 
  const [dataForCopy, setDataForCopy] = useState(null);

  // cuando ag grid esta listo, almaneca los datos de la api en el state 
  // when the grid is onGridReady, it store the api on state 
  const onGridReady = async (params) => {
    setGridApi(params.api);
    setGridApiContext(params.api)
    setGridColumnApi(params.columnApi);
  };

  // funcion que se ejcuta al hacer click en una de las filas para mostrar el modal de la transaccion 
  // on click rows func to show the modal whit transaction data 
  const onSelection = () => {
    if (roles.includes('user') || roles.includes('moderator') || roles.includes('administrator')) {
      let selectedRows = gridApi.getSelectedRows();
      if (selectedRows[0].processed) {
        if (
          (roles.includes('moderator') || roles.includes('administrator'))
          && !selectedRows[0].processed_by.includes("Automatic")
          && (selectedRows[0].processed_for === "INTERNAL")
        ) {
          setTransData(selectedRows[0])
          setIsOpenRevert(true)
        }
        if (
          (roles.includes('moderator') || roles.includes('administrator'))
          && selectedRows[0].processed_by.includes("@yummysuperapp.com")
          && selectedRows[0].user_type === "YUMMER"
        ) {
          setTransData(selectedRows[0])
          setIsOpenRevert(true)
        }

      } else {
        setTransData(selectedRows[0])
        setIsOpen(true)
      }
    }
  }

  // parametros de filtro para la columna de fechas 
  // filter params for date column filter 
  var filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('/');
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000
  };

  //   formmater para mostrar el monto en dolares con el signo 
  //   formatter for show the dolar info 
  var dolarValueFormatter = function (params) {
    let formatted = params.data.amount;
    return '$ ' + formatted;
  };

  //   formmater para mostrar el monto en bolivares con el signo 
  //   formatter for show the bolivares info 
  var bolivarValueFormatter = function (params) {
    let formatted = params.data.amountBSD;
    formatted === '' ? formatted = '' : formatted = formatted + ' Bs.'
    return formatted
  };

  //   formmater para mostrar el monto en bolivares con el signo 
  //   formatter for show the bolivares info 
  var reimbursementBolivarValueFormatter = function (params) {
    let formatted = params.data.reimbursement_amount;

    if (formatted === undefined)
      formatted = 0;
    
    formatted === '' ? formatted = '' : formatted = formatted + ' Bs.'
    return formatted
  };

  // esta funcion evita el rezise de la celda al hacer click sobre ella 
  // this func disable cell rezise on click event 
  const cellClicked = (data) => {
    if (window.getSelection().type !== 'Range') {
      //text has not been selected, the cell has been clicked
      // console.log('cellClicked');
    }
  }

  const statusFormatter = (params) => {
    let processed = params.data.processed
    if (processed) {
      return "Procesado"
    } else {
      return "Sin procesar"
    }
  }


  const onFilterChanged = (data) => {
    const rows = gridApi.getDisplayedRowCount()
    // console.log('rows', rows)
    setRowsCount(rows)
  }

  // si es la vista de rechazado retorna este jsx 
  // if is rejected view, returns this jsx 
  if (isRejected) {
    return (
      <AgGridReact
        defaultColDef={{
          editable: false,
          floatingFilter: true, // necesary to show custom components on column filters
          suppressMenu: true, // supress mini menu on all colunms filters
          floatingFilterComponentParams: { suppressFilterButton: true },
          rowDeselection: true, // allow deselection on rows
          filter: true, // show filters on columns
        }}
        overlayNoRowsTemplate={ //this is the message when the data is fetching
          `<span className="ag-overlay-loading-center loader">Cargando los datos, por favor espera...</span>`
        }
        frameworkComponents={{ //here you can put the custom components, then you cant call they on agGridColumn
          Platform: Platform, // custom component to show platform column with icon
          agDateInput: CustomDateComponent, //custom date picker on date column filter
          platformFilter: PlatformFilter // custom select input on platform filter column
        }}
        gridOptions={{
          // suppressRowTransform: true,
          // enableCellTextSelection: true,
          // ensureDomOrder: true
        }}
        onFilterChanged={onFilterChanged}
        rowClass="table-rows pointer"
        rowSelection={'single'}
        animateRows={true}
        onGridReady={onGridReady}
        onRowClicked={onSelection}
        headerHeight={40}
        rowHeight={40}
        rowData={trans}>
        <AgGridColumn
          cellClass="cell-id cell-center"
          headerName="ID"
          field="auto_incremental_id"
          width={100}
          filter="agTextColumnFilter"
          sortable={true}
          unSortIcon={true}
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Titular"
          resizable={true}
          field="name"
          width={180}
          filter="agTextColumnFilter"
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Concepto"
          resizable={true}
          field="concept"
          flex={1}
          minWidth={180}
          filter="agTextColumnFilter"
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Tipo de usuario"
          resizable={true}
          field="user_type"
          flex={1}
          minWidth={180}
          filter="agTextColumnFilter"
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Vertical"
          resizable={true}
          field="vertical"
          flex={1}
          minWidth={180}
          filter="agTextColumnFilter"
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Monto $"
          field="amount"
          width={150}
          valueFormatter={dolarValueFormatter}
          filter="agNumberColumnFilter"
          sortable={true}
          unSortIcon={true}
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Monto Bs"
          field="amountBSD"
          width={150}
          valueFormatter={bolivarValueFormatter}
          filter="agNumberColumnFilter"
          sortable={true}
          unSortIcon={true}
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Monto Reembolsado Bs"
          field="reimbursement_amount"
          width={150}
          valueFormatter={reimbursementBolivarValueFormatter}
          filter="agNumberColumnFilter"
          sortable={true}
          unSortIcon={true}
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Fecha"
          field="date"
          width={200}
          filter="agDateColumnFilter"
          sortable={true}
          unSortIcon={true}
          filterParams={filterParams}
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center"
          headerName="Hora"
          field="time"
          width={100}
          filter="agTextColumnFilter"
          sortable={true}
          unSortIcon={true}
        ></AgGridColumn>
        <AgGridColumn
          cellClass="hidden cell-center overflow"
          headerName="Tipo"
          field="platform"
          cellRenderer="Platform"
          width={180}
          filter="agTextColumnFilter"
          floatingFilterComponent="platformFilter"
          floatingFilterComponentParams={{ suppressFilterButton: true }}></AgGridColumn>
        <AgGridColumn
          hide={true}
          headerName="Status"
          field="status"
          width={180} ></AgGridColumn>
      </AgGridReact>
    )
  } else {
    // si es la vista de lista retorna este jsx 
    // if is list view, returns this jsx 
    return (
      <>
        <AgGridReact
          defaultColDef={{
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
          }}
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center loader">Cargando los datos, por favor espera...</span>'
          }
          frameworkComponents={{
            Platform: Platform,
            IdComponent: IdComponent, //custom id component to show on cells, put in "cellRenderer"
            agDateInput: CustomDateComponent,
            platformFilter: PlatformFilter
          }}
          gridOptions={{
            enableCellTextSelection: true,
            ensureDomOrder: true
          }}
          onFilterChanged={onFilterChanged}
          rowClass="table-rows"
          // onCellClicked={onCopyCell}
          rowSelection={'single'}
          onRowClicked={onSelection}
          onGridReady={onGridReady}
          headerHeight={40}
          animateRows={true}
          rowHeight={40}
          onCellClicked={cellClicked}
          suppressExcelExport={true}
          popupParent={document.body}
          rowData={trans}>
          <AgGridColumn
            cellClass="cell-center overflow"
            headerName="ID"
            field="auto_incremental_id"
            cellRenderer="IdComponent"
            width={100}
            filter="agTextColumnFilter"
            sortable={true}
            unSortIcon={true}
          ></AgGridColumn>
          <AgGridColumn
            cellClass="cell-center"
            headerName="Titular"
            resizable={true}
            field="name"
            width={180}
            filter="agTextColumnFilter"
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Asociado a"
            resizable={true}
            field="associated_to"
            flex={1}
            minWidth={200}
            filter="agTextColumnFilter"
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Tipo de usuario"
            resizable={true}
            field="user_type"
            flex={1}
            minWidth={180}
            filter="agTextColumnFilter"
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Vertical"
            resizable={true}
            field="vertical"
            flex={1}
            minWidth={200}
            filter="agTextColumnFilter"
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Procesado por"
            resizable={true}
            field="processed_by"
            flex={1}
            minWidth={200}
            filter="agTextColumnFilter"
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Concepto"
            resizable={true}
            field="concept"
            flex={1}
            minWidth={180}
            filter="agTextColumnFilter"
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Monto $"
            field="amount"
            width={150}
            valueFormatter={dolarValueFormatter}
            filter="agNumberColumnFilter"
            sortable={true}
            unSortIcon={true}
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Monto Bs"
            field="amountBSD"
            width={150}
            valueFormatter={bolivarValueFormatter}
            filter="agNumberColumnFilter"
            sortable={true}
            unSortIcon={true}
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Monto Reembolsado Bs"
            field="reimbursement_amount"
            width={150}
            valueFormatter={reimbursementBolivarValueFormatter}
            filter="agNumberColumnFilter"
            sortable={true}
            unSortIcon={true}
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Fecha"
            field="date"
            width={200}
            filter="agDateColumnFilter"
            filterParams={filterParams}
            sortable={true}
            unSortIcon={true}
          ></AgGridColumn>
          <AgGridColumn
            cellClass="hidden cell-center"
            headerName="Hora"
            field="time"
            width={100}
            filter="agTextColumnFilter"
            sortable={true}
            unSortIcon={true}
          ></AgGridColumn>
          <AgGridColumn
            cellClass="cell-center overflow"
            headerName="Tipo"
            field="platform"
            cellRenderer="Platform"
            width={180}
            filter="agTextColumnFilter"
            floatingFilterComponent="platformFilter"
            floatingFilterComponentParams={{ suppressFilterButton: true }}
            m ></AgGridColumn>
          <AgGridColumn
            hide={true}
            headerName="Status"
            field="status"
            width={180} ></AgGridColumn>
        </AgGridReact>
      </>
    )
  }
}

