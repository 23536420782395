import React, {
    forwardRef,
    Fragment,
    useImperativeHandle,
    useRef,
    useState,
  } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

  
  export default forwardRef((props, ref) => {
    const [currentValue, setCurrentValue] = useState("");
    const inputRef = useRef(null);
  
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          // When the filter is empty we will receive a null value here
          if (!parentModel) {
            inputRef.current.value = '';
            setCurrentValue(null);
          } else {
            inputRef.current.value = parentModel.filter + '';
            setCurrentValue(parentModel.filter);
          }
        },
      };
    });
  
    const onChangeSelect = (e) => {
        console.log(e.target.value)
      if (e.target.value === '') {
        // Remove the filter
        props.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(null, null);
        });
        return;
      }
  
      setCurrentValue(e.target.value);
      props.parentFilterInstance((instance) => {
        // necesita ser "equals" para que remueva el filtro en caso de ser vacio 
        // need to be "equals" to remove de filter in empty string case 
        instance.onFloatingFilterChanged('equals', e.target.value);
      });
    };
  
    const clearDate = () => {
        const value = {
            target: {
                value: ''
            }
        }
        onChangeSelect(value)
        setCurrentValue('');
    return;
    }
  
    return (
      <div className="select-platform-container" >
        <select className="select-platform-input" ref={inputRef} value={currentValue} onChange={onChangeSelect}>
            <option value="true">SI</option>
            <option value="false">NO</option>
            <option value=""></option>
        </select>
        <HighlightOffIcon className="clear-btn" style={{width: 18}} onClick={()=> clearDate()} />
      </div>
    );
  });